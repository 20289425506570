import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

type SimpleSubmitDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  title: string
  content: string
}

export default function SimpleSubmitDialog({ open, onClose, title, content, onSubmit }: SimpleSubmitDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Vazgeç</Button>
        <Button variant='contained' onClick={onSubmit}>Onayla</Button>
      </DialogActions>
    </Dialog>
  )
}
