import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import useSWR, { mutate } from 'swr'
import AdminUsersTable from '../components/AdminUsersTable'
import PageHeader from '../components/PageHeader'
import { AuthContext } from '../contexts/AuthProvider'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'
import { ApiList, User } from '../utils/types'

export default function AdminUsers() {

  const { token } = useContext(AuthContext);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('id:asc')

  const { data: users, isLoading, error } = useSWR<ApiList<User>>(
    `${baseUrl}/users?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}&orderBy=${orderBy}`,
    (url: string) => fetcher(url, token));

  useEffect(() => {
    setPage(1);
  }, [recordPerPage, search])

  const handleMutate = () => {
    mutate(`${baseUrl}/users?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}&orderBy=${orderBy}`);
  }

  useEffect(() => {
    setPage(1);
  }, [search])

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Hesaplar' },
        ]}
        title="Hesaplar"
      />
      <Box
        sx={{
          mt: 2
        }}
      >
        <AdminUsersTable
          users={users?.results || []}
          count={users?.count || 0}
          recordPerPage={recordPerPage}
          setRecordPerPage={setRecordPerPage}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          handleMutate={handleMutate}
        />
      </Box>
    </Box>
  )
}
