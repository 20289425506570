import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import MenuItemComponent from './MenuItemComponent';
import logo from '../assets/logo.svg';
import { useLocation } from 'react-router-dom';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthProvider';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Role } from '../utils/types';

const drawerWidth = 290;

const Sidebar = () => {

  const loc = useLocation();
  const { logout, role } = useContext(AuthContext);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={true}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <div style={{ padding: '10px', textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
        <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
      <List>
        <MenuItemComponent
          icon={<HomeIcon />}
          text="Anasayfa"
          selected={loc.pathname === '/'}
          to="/"
        />
        <MenuItemComponent
          icon={<PeopleIcon />}
          text="Kullanıcılar"
          selected={loc.pathname.includes('/users')}
          to="/users"
        />
        <MenuItemComponent
          icon={<AppRegistrationIcon />}
          text="Sağlık Turizmi"
          selected={loc.pathname.includes('/health-tourism')}
          to="/health-tourism"
        />
        <MenuItemComponent
          icon={<InterpreterModeIcon />}
          text="Görüşme Talepleri"
          selected={loc.pathname.includes('/meeting-requests')}
          to="/meeting-requests"
        />
        {role === Role.SUPERADMIN && (
          <MenuItemComponent
            icon={<LocalPharmacyIcon />}
            text="Klinikler"
            selected={loc.pathname.includes('/clinics')}
            to="/clinics"
          />
        )}
        <MenuItemComponent
          icon={<EventIcon />}
          text="Randevular"
          selected={loc.pathname === '/appointments'}
          to="/appointments"
        />
        {role === Role.SUPERADMIN && (
          <MenuItemComponent
            icon={<AdminPanelSettingsIcon />}
            text="Hesaplar"
            selected={loc.pathname === '/accounts'}
            to="/accounts"
          />
        )}
        {role === Role.SUPERADMIN && (
          <MenuItemComponent
            icon={<CampaignIcon />}
            text="Kampanyalar"
            selected={loc.pathname === '/campaigns'}
            to="/campaigns"
          />
        )}
      </List>
      <Box
        sx={{
          flexGrow: 1,
        }}
      />
      <List>
        <ListItemButton
          onClick={() => logout!()}
          sx={{
            width: '100%',
            px: 3,
            color: 'red',
            backgroundColor: 'transparent',
            position: 'relative',
            '&:hover': {
              backgroundColor: 'transparent'
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: '40px',
              color: 'red',
            }}
          >
            <Logout />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;
