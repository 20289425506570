import { Box, Paper, Typography } from '@mui/material';
import React from 'react'
import CustomBreadCrumbs from './CustomBreadCrumbs';

type PageHeaderProps = {
  breadcrumbItems: { label: string, to?: string }[];
  title: string;
}

export default function PageHeader({ breadcrumbItems, title }: PageHeaderProps) {

  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          p: 3,
          borderRadius: '10px'
        }}
      >
        <CustomBreadCrumbs
          items={breadcrumbItems}
        />
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </Paper>
    </Box>
  )
}
