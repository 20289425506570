import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const createClinic = async (values: any, token: string) => {
  const url = `${baseUrl}/clinics`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateClinic = async (id: number, values: any, token: string) => {
  const url = `${baseUrl}/clinics/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const deleteClinic = async (id: number, token: string) => {
  const url = `${baseUrl}/clinics/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const res = await handleResponse(response);
  return res;
}

export const addAppointment = async (values: any, token: string) => {
  const url = `${baseUrl}/appointments`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateAppointment = async (id: number, values: any, token: string) => {
  const url = `${baseUrl}/appointments/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateUser = async (id: number, values: any, token: string) => {
  const url = `${baseUrl}/profiles/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateAppUser = async (id: number, values: any, token: string) => {
  const url = `${baseUrl}/users/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateInterviewStatus = async (id: number, value: string, token: string) => {
  const url = `${baseUrl}/profiles/interview-status/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ interviewStatus: value })
  });
  const res = await handleResponse(response);
  return res;
}

export const updateHealthTourism = async (id: number, value: string, token: string) => {
  const url = `${baseUrl}/profiles/health-tourism/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ healthTourism: value })
  });
  const res = await handleResponse(response);
  return res;
}

export const sendNotificationData = async (title: string, content: string, token: string, id?: number) => {
  const url = `${baseUrl}/notifications`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId: id, title, content })
  });
  const res = await handleResponse(response);
  return res;
}

export const getProlife = async (id: number, printMode: boolean, token: string) => {
  const url = `${baseUrl}/profiles/${id}?printMode=${printMode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const getShareProfileLink = async (id: number, token: string) => {
  const url = `${baseUrl}/profiles/profile-link/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const getMetrics = async (token: string) => {
  const url = `${baseUrl}/profiles/count-metrics`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const getLastAppointments = async (token: string) => {
  const url = `${baseUrl}/appointments?skip=0&take=5&orderBy=id:desc&status=WAITING`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const getLastInterviews = async (token: string) => {
  const url = `${baseUrl}/profiles?skip=0&take=5&orderBy=interviewStatus:desc&interviewStatus=WAITING`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const getLastHealthTourism = async (token: string) => {
  const url = `${baseUrl}/profiles?skip=0&take=5&orderBy=healthTourism:desc&healthTourism=WAITING`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}

export const updateDiagnosisStatus = async (id: number, value: boolean, token: string) => {
  const url = `${baseUrl}/diagnoses/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ selectedStatus: value })
  });
  const res = await handleResponse(response);
  return res;
}

export const updateSmileDesignStatus = async (id: number, value: boolean, token: string) => {
  const url = `${baseUrl}/smile-designs/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ selectedStatus: value })
  });
  const res = await handleResponse(response);
  return res;
}

export const shareProfileWithClinic = async (clinicId: number, profileId: number, token: string) => {
  const url = `${baseUrl}/appointments/share-profile-with-clinic?profileId=${profileId}&clinicId=${clinicId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}


export const addAdmin = async (values: any, token: string) => {
  const url = `${baseUrl}/auth/add-admin`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      ...values,
      role: 'ADMIN'
    })
  });
  const res = await handleResponse(response);
  return res;
}

export const adminPasswordReset = async (values: any, token: string) => {
  const url = `${baseUrl}/auth/admin-password-reset`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}


export const addCampaign = async (values: any, token: string) => {
  const url = `${baseUrl}/campaigns`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const updateCampaign = async (id: number, values: any, token: string) => {
  const url = `${baseUrl}/campaigns/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });
  const res = await handleResponse(response);
  return res;
}

export const deleteCampaign = async (id: number, token: string) => {
  const url = `${baseUrl}/campaigns/${id}`;
  const response = await fetch(url, {
    method: 'DELETE', 
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const res = await handleResponse(response);
  return res;
}


export const deleteUser = async (id: number, token: string) => {
  const url = `${baseUrl}/users/${id}`;
  const response = await fetch(url, {
    method: 'DELETE', 
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });
  const res = await handleResponse(response);
  return res;
}
