import { Box, Button, Card, CardActions, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Campaign } from '../utils/types';
import placeholderImge from '../assets/image-placeholder.svg';
import DeleteIcon from '@mui/icons-material/Delete';

type AddEditCampaignDialogProps = {
  open: boolean;
  onClose: () => void;
  campaign?: Campaign;
  onSubmit: (campaign: Campaign) => void;
}

export default function AddEditCampaignDialog({ open, onClose, campaign, onSubmit }: AddEditCampaignDialogProps) {

  const [title, setTitle] = useState<string>('{ "tr": "", "en": "" }')
  const [description, setDescription] = useState<string>('{ "tr": "", "en": "" }')
  const [link, setLink] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [priority, setPriority] = useState<number>(1)

  useEffect(() => {
    if (campaign) {
      setTitle(campaign.title)
      setDescription(campaign.description || '')
      setLink(campaign.link || '')
      setImage(campaign.image)
      setStatus(campaign.status || false)
      setPriority(campaign.priority || 1)
    } else {
      setTitle('{ "tr": "", "en": "" }')
      setDescription('{ "tr": "", "en": "" }')
      setLink('')
      setImage('')
      setStatus(true)
      setPriority(1)
    }
  }, [campaign])

  const handleImageSelect = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target?.result as string;
      setImage(url);
    }
    reader.readAsDataURL(file);
  }

  const handleImageDelete = () => {
    setImage('');
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogTitle>
        {campaign ? 'Kampanya Düzenle' : 'Yeni Kampanya'}
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({
            title,
            description,
            link,
            image,
            status,
            priority
          })
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Card sx={{ display: 'inline-block' }}>
              <Typography variant="h6" component="div" sx={{ p: 2 }}>
                Fotoğraf
              </Typography>
              <CardMedia
                component="img"
                image={image || placeholderImge}
                alt="cover-image"
                sx={{ maxHeight: 350, objectFit: 'contain' }}
              />
              <CardActions>
                <Button component="label" size="large" color="primary">
                  RESİM EKLE
                  <input type="file" hidden accept="image/*" onChange={(e) => handleImageSelect(e)} />
                </Button>
                <IconButton aria-label="delete" size="small" color="primary" onClick={() => { handleImageDelete() }}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
            <TextField
              label="Başlık TR"
              variant="outlined"
              value={JSON.parse(title)['tr']}
              onChange={(e) => setTitle(JSON.stringify({ tr: e.target.value, en: JSON.parse(title)['en'] }))}
            />
            <TextField
              label="Başlık EN"
              variant="outlined"
              value={JSON.parse(title)['en']}
              onChange={(e) => setTitle(JSON.stringify({ tr: JSON.parse(title)['tr'], en: e.target.value }))}
            />
            <TextField
              label="Açıklama TR"
              variant="outlined"
              value={JSON.parse(description)['tr']}
              onChange={(e) => setDescription(JSON.stringify({ tr: e.target.value, en: JSON.parse(description)['en'] }))}
              multiline
              rows={4}
            />
            <TextField
              label="Açıklama EN"
              variant="outlined"
              value={JSON.parse(description)['en']}
              onChange={(e) => setDescription(JSON.stringify({ tr: JSON.parse(description)['tr'], en: e.target.value }))}
              multiline
              rows={4}
            />
            <TextField
              label="Link"
              variant="outlined"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <TextField
              label="Öncelik"
              variant="outlined"
              value={priority || ''}
              onChange={(e) => setPriority(Number(e.target.value))}
              type="number"
            />
            {/* <FormControlLabel control={<Switch defaultChecked />} label="Label" /> */}
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                  color="primary"
                />
              }
              label="Aktif"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>İptal</Button>
          <Button type="submit" variant="contained" color="primary">Kaydet</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
