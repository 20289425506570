import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import ClinicAutocomplete from './ClinicAutocomplete';
import { Clinic } from '../utils/types';

type SelecetClinicDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (clinicId: number) => void;
  onCopy: () => void;
}

export default function SelecetClinicDialog({ open, onClose, onSubmit, onCopy }: SelecetClinicDialogProps) {

  const [selectedClinic, setSelectedClinic] = React.useState<Clinic | null>(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <Typography>
          Kliniği Seç
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 2
          }}
        >
          <ClinicAutocomplete
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCopy}>Kopyala</Button>
        <Button onClick={onClose}>İptal</Button>
        <Button variant='contained' onClick={() => { selectedClinic && onSubmit(selectedClinic.id) }}>Tamam</Button>
      </DialogActions>

    </Dialog>
  )
}
