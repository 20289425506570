import { Box, Checkbox, Dialog, IconButton, Modal, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import aiImage from '../assets/ai.svg';
import a1 from '../assets/agiz-ici-1.jpeg';
import a2 from '../assets/agiz-ici-2.jpeg';
import a3 from '../assets/agiz-ici-3.jpeg';
import r from '../assets/agiz-rontgen.png';
import { Close } from '@mui/icons-material';
import { Diagnosis, Expertise, User } from '../utils/types';
import { clinicExpertises } from '../utils/defaults';
import { updateDiagnosisStatus } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthProvider';
import { mutate } from 'swr';
import { baseUrl } from '../utils/constants';

export type DiagnosisData = {
  diagnosis: Array<{ [key: string]: number }>;
};

type DentalAnamnesisTableRowItemProps = {
  item: Diagnosis
  editMode?: boolean
  handleUpdateDiagnosesStatus?: (id: number, status: boolean) => void
}

export default function DentalAnamnesisTableRowItem({ item, editMode, handleUpdateDiagnosesStatus }: DentalAnamnesisTableRowItemProps) {

  const [selectedPhoto, setSelectedPhoto] = React.useState('');
  const { token } = useContext(AuthContext);

  const handleCloseModal = () => {
    setSelectedPhoto('');
  }

  function formatDiagnosis(jsonString: string): string[] {
    const parsedData: DiagnosisData = JSON.parse(jsonString);
    const groupedDiagnosis = parsedData.diagnosis.reduce((acc, item) => {
      const [key, value] = Object.entries(item)[0];
      acc[key] = acc[key] || [];
      if (!acc[key].includes(value)) {
        acc[key].push(value);
      }
      return acc;
    }, {} as { [key: string]: number[] });

    return Object.entries(groupedDiagnosis)
      .map(([key, values]) => `${key}: ${values.join(', ')}`);
  }


  const expertises = clinicExpertises.filter(expertise => {
    return item[`${expertise.value as Expertise}`];
  });

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 400,
            minWidth: 300,
            gap: 2
          }}
        >
          {editMode && (
            <Checkbox
              checked={item.selectedStatus || false}
              onChange={() => handleUpdateDiagnosesStatus!(item.id, !item.selectedStatus)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                fontSize: 20
              }}
            />
          )}
          <Box
            sx={{
              background: '#ECF0F3',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: '0 0 56px',
              width: 56,
              height: 56
            }}
          >
            <img width={24} src={aiImage} alt="ai-image" />
          </Box>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {item.aiDiagnosis
              ? formatDiagnosis(item.aiDiagnosis).map((diagnosis, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {diagnosis}
                </Typography>
              ))
              : item.dentalAnamnesis || 'Tanı Bulunamadı'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {expertises.map((department, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 14,
            }}
          >
            {department.label}
          </Typography>
        ))}
      </TableCell>
      <TableCell>
        <Box
          sx={{
            background: '#ECF0F3',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            px: 2,
            py: 1
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {item.complaint}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography>
          {new Date(item.createdAt).toLocaleDateString()}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          {item.images && item.images.map((image, index) => (
            <img
              key={index}
              src={image}
              onClick={() => setSelectedPhoto(image)}
              alt="a1"
              style={{
                width: 52,
                height: 52,
                borderRadius: 10,
                objectFit: 'cover',
                cursor: 'pointer'
              }}
            />
          ))}
        </Box>
      </TableCell>
      <TableCell>
        <img
          src={item.result}
          onClick={() => (item.result && setSelectedPhoto(item.result))}
          alt="r"
          style={{
            width: 52,
            height: 52,
            borderRadius: 10,
            objectFit: 'cover',
            cursor: 'pointer'
          }}
        />
      </TableCell>
      <TableCell>
        <img
          src={item.doctorReport}
          onClick={() => (item.doctorReport && setSelectedPhoto(item.doctorReport))}
          alt="r"
          style={{
            width: 52,
            height: 52,
            borderRadius: 10,
            objectFit: 'cover',
            cursor: 'pointer'
          }}
        />
      </TableCell>
      <Modal
        open={selectedPhoto !== ''}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              background: 'darkgray',
              '&:hover': {
                background: 'gray',
              },
            }}
            onClick={handleCloseModal}
          >
            <Close />
          </IconButton>
          <img
            src={selectedPhoto}
            alt={selectedPhoto}
            style={{ maxWidth: '95vw', maxHeight: '95vh' }}
          />
        </Box>
      </Modal>
    </TableRow>
  )
}
