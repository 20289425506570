// src/components/Layout.tsx

import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';

const Layout = (props: { children: any }) => {

  const loc = useLocation();

  if (loc.pathname === '/login') return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* <Header /> */}
      <div style={{ display: 'flex', flexGrow: 1 }}> {/* Header yüksekliğine göre ayarla */}
        <Sidebar />
        <main style={{ flexGrow: 1, padding: '10px' }}>
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
