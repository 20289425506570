import React from 'react'
import { User } from '../utils/types'
import { Box, Checkbox, IconButton, Modal, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

type SmileDesignProps = {
  user: User;
  printMode?: boolean;
  editMode?: boolean;
  handleUpdateSmileDesignStatus?: (id: number, status: boolean) => void;
}

export default function SmileDesign({ user, printMode, handleUpdateSmileDesignStatus, editMode }: SmileDesignProps) {

  const [selectedPhoto, setSelectedPhoto] = React.useState('');
  const handleCloseModal = () => {
    setSelectedPhoto('');
  }

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: '#32353E'
          }}
        >
          Gülüş Tasarımı
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            color: '#9DA0AF',
          }}
        >
          Burada kullanıcının gülüş tasarımı bilgileri yer almaktadır
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: (editMode || printMode) ? 'column' : undefined,
          gap: 2,
          flexWrap: (editMode || printMode) ? 'wrap' : 'nowrap',
        }}
      >
        {user.smileDesign.map((item, index) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={index}>
            {editMode && (
              <Checkbox
                checked={item.selectedStatus}
                onChange={(e) => {
                  handleUpdateSmileDesignStatus && handleUpdateSmileDesignStatus(item.id, e.target.checked)
                }}
              />
            )}
            <img
              src={item.image}
              onClick={() => (item.image && setSelectedPhoto(item.image))}
              alt="r"
              style={{
                width: printMode ? 400 : 120,
                borderRadius: 10,
                objectFit: 'cover',
                cursor: 'pointer'
              }}
            />
            <Modal
              open={selectedPhoto !== ''}
              onClose={handleCloseModal}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: 'white',
                    background: 'darkgray',
                    '&:hover': {
                      background: 'gray',
                    },
                  }}
                  onClick={handleCloseModal}
                >
                  <Close />
                </IconButton>
                <img
                  src={selectedPhoto}
                  alt={selectedPhoto}
                  style={{ maxWidth: '95vw', maxHeight: '95vh' }}
                />
              </Box>
            </Modal>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
