import { Box, Typography } from '@mui/material';
import React from 'react'

type MetricBoxProps = {
  title: string;
  value: number;
}

export default function MetricBox({ title, value }: MetricBoxProps) {
  return (
    <Box
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <Box>
        <Typography
        >{title}</Typography>
      </Box>
      <Box>
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Box>
  )
}
