import { Box, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import userPic from "../assets/user-pic.png"
import { AuthContext } from '../contexts/AuthProvider'
import { clinicExpertises } from '../utils/defaults'
import { Appointment, Expertise } from '../utils/types'
import { useAlert } from '../utils/useAlert'
import CustomButton from './CustomButton'

type AppointmentTableRowItemProps = {
  item: Appointment;
  handleClickEdit: (appointment: Appointment) => void;
  handleChangeStatus: (id: number, status: string) => void;
}

export default function AppointmentTableRowItem({ item, handleClickEdit, handleChangeStatus }: AppointmentTableRowItemProps) {

  const expertises = clinicExpertises.filter(expertise => {
    return item[`${expertise.value as Expertise}`];
  });
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img style={{ height: 24, width: 24 }} src={item.profile?.image || userPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>
            {item.profile.firstName} {item.profile.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography style={{ fontSize: 12 }}>
            {item.clinic.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {expertises.map((expertise, index) => (
            <Typography
              key={index}
              style={{
                fontSize: 12,
              }}
            >
              {expertise.label}
            </Typography>
          ))}
        </Box>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {
            new Date(item.date).toLocaleDateString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
          }
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            mx: 'auto',
            cursor: 'pointer',
            ':hover': {
              color: 'primary.main'
            }
          }}
          onClick={handleClick}
        >
          {item.status === 'WAITING' && <Typography style={{ fontSize: 12 }}>Bekliyor</Typography>}
          {item.status === 'COMPLETED' && <Typography style={{ fontSize: 12 }}>Tamamlandı</Typography>}
          {item.status === 'CANCELLED' && <Typography style={{ fontSize: 12 }}>İptal Edildi</Typography>}
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => { handleChangeStatus(item.id, 'WAITING'); setAnchorEl(null) }}>Bekliyor</MenuItem>
          <MenuItem onClick={() => { handleChangeStatus(item.id, 'COMPLETED'); setAnchorEl(null) }}>Tamamlandı</MenuItem>
          <MenuItem onClick={() => { handleChangeStatus(item.id, 'CANCELLED'); setAnchorEl(null) }}>İptal Edildi</MenuItem>
        </Menu>
      </TableCell>
      <TableCell align="center">
        <CustomButton
          label="Düzenle"
          variant="contained"
          size="small"
          sx={{
            mx: 'auto'
          }}
          onClick={() => { handleClickEdit(item) }}
        />
      </TableCell>
    </TableRow>
  )
}
