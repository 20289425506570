import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Fab, Grid, Link, Typography } from '@mui/material'
import React, { useContext } from 'react'
import PageHeader from '../components/PageHeader'
import useSWR, { mutate } from 'swr';
import { Campaign } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { AuthContext } from '../contexts/AuthProvider';
import AddEditCampaignDialog from '../components/AddEditCampaignDialog';
import { useAlert } from '../utils/useAlert';
import { addCampaign, deleteCampaign, updateCampaign } from '../services/AppServices';
import { link } from 'fs';
import { image } from 'html2canvas/dist/types/css/types/image';
import { title } from 'process';
import SimpleConfirmDialog from '../components/SimpleConfirmDialog';

export default function Campaigns() {

  const { token } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [selectedCampaign, setSelectedCampaign] = React.useState<Campaign>();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const showSnackbar = useAlert();

  const handleDelete = async () => {
    try {
      if (selectedCampaign) {
        const res = await deleteCampaign(selectedCampaign.id!, token);
        mutate(`${baseUrl}/campaigns/admin`);
        showSnackbar('Kampanya silindi', 'success');
        setConfirmOpen(false);
      }
    } catch (e) {
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const handleSubmit = async (campaign: Campaign) => {
    try {
      if (selectedCampaign) {
        const res = await updateCampaign(selectedCampaign.id!, campaign, token);
        mutate(`${baseUrl}/campaigns/admin`);
        showSnackbar('Kampanya güncellendi', 'success');
      } else {
        const res = await addCampaign(campaign, token);
        mutate(`${baseUrl}/campaigns/admin`);
        showSnackbar('Kampanya eklendi', 'success');
      }
      setOpen(false);
      setSelectedCampaign(undefined);
    } catch (e) {
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const { data: campagins, isLoading, error } = useSWR<Campaign[]>(
    `${baseUrl}/campaigns/admin`,
    (url: string) => fetcher(url, token));

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Kampanyalar' },
        ]}
        title="Kampanyalar"
      />
      <Box
        sx={{
          mt: 2
        }}>
        <Grid container spacing={2}>
          {campagins?.map((campaign) => (
            <Grid item xs={12} sm={6} md={4} key={campaign.id}>
              <Card sx={{ mb: 2, boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={campaign.image}
                  alt={campaign.title}
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    {JSON.parse(campaign.title).tr}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                    {campaign.description ?  JSON.parse(campaign.description).tr : ''}
                  </Typography>
                  <Link href={campaign.link} target="_blank">
                    {campaign.link}
                  </Link>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Chip
                      label={campaign.status ? 'Aktif' : 'Pasif'}
                      color={campaign.status ? 'success' : 'default'}
                      variant="outlined"
                    />
                    <Typography variant="body2">
                      Öncelik: {campaign.priority}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    variant='outlined'
                    size="small" onClick={() => {
                      setSelectedCampaign(campaign);
                      setOpen(true);
                    }
                    }>
                    Düzenle
                  </Button>
                  <Button
                    variant='outlined'
                    color='error'
                    size="small" onClick={() => {
                      setSelectedCampaign(campaign);
                      setConfirmOpen(true);
                    }
                    }>
                    SİL
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Fab
        variant="extended"
        color='primary'
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          textTransform: 'none'
        }}
      >
        Yeni Kampanya
      </Fab>
      <AddEditCampaignDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        campaign={selectedCampaign}
      />
      <SimpleConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title="Kampanyayı sil"
        content="Kampanyayı silmek istediğinize emin misiniz?"
        onConfirm={handleDelete}
      />
    </Box>
  )
}
