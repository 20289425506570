import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import PageHeader from '../components/PageHeader'
import AppointmentTable from '../components/AppointmentTable'
import { AuthContext } from '../contexts/AuthProvider';
import useSWR, { mutate } from 'swr';
import { ApiList, Appointment, Clinic } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import AddEditAppointmentDialog from '../components/AddEditAppointmentDialog';
import { addAppointment, updateAppointment } from '../services/AppServices';
import { useAlert } from '../utils/useAlert';

export default function Appointments() {

  const { token } = useContext(AuthContext);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();
  const showSnackbar = useAlert();

  const onClose = () => {
    setOpen(false);
    setSelectedAppointment(undefined);
  }

  const onSubmit = async (date: string, clinic: Clinic, expertises: any, description: string) => {
    try {
      const newAppointment = {
        profileId: selectedAppointment?.profile.id,
        date,
        clinicId: clinic.id,
        description,
        pedodontics: expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
        periodontology: expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
        surgery: expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
        restorative: expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
        endodontics: expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
        protetics: expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
        orthodontics: expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
        radiologyGeneral: expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
      };

      const res = await updateAppointment(selectedAppointment?.id!, newAppointment, token);
      mutate(`${baseUrl}/appointments?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}`);
      showSnackbar('Randevu başarıyla güncellendi', 'success');
      setOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const { data: appointments, isLoading, error } = useSWR<ApiList<Appointment>>(
    `${baseUrl}/appointments?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}`,
    (url: string) => fetcher(url, token));

  useEffect(() => {
    setPage(1);
  }, [recordPerPage])

  const handleClickEdit = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setOpen(true);
  }

  const handleChangeStatus = async (id: number, status: string) => {
    try {
      const res = await updateAppointment(id, { status }, token);
      showSnackbar('Randevu başarıyla güncellendi', 'success');
      mutate(`${baseUrl}/appointments?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}`);
    } catch (error) {
      console.log(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Randevular' },
        ]}
        title="Randevular"
      />
      <Box
        sx={{
          mt: 2
        }}
      >
        <AppointmentTable
          appointments={appointments?.results || []}
          count={appointments?.count || 0}
          recordPerPage={recordPerPage}
          setRecordPerPage={setRecordPerPage}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          handleClickEdit={handleClickEdit}
          handleChangeStatus={handleChangeStatus}
        />
      </Box>
      <AddEditAppointmentDialog
        open={open}
        onClose={onClose}
        appointment={selectedAppointment}
        onSubmit={onSubmit}
      />
    </Box>
  )
}
