import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const registerUser = async (values: any) => {
  const url = `${baseUrl}/auth/register`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      }
    )
  });
  const res = await handleResponse(response);
  return res;

}

export const loginUser = async (email: string, password: string) => {
  const url = `${baseUrl}/auth/admin-login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        email: email,
        password: password,
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const refreshToken = async (token: string) => {
  const url = `${baseUrl}/api/v1/Auth/RefreshToken`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        token
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const changePassword = async (password: string, newPassword: string, newPassword2: string, token: string) => {
  const url = `${baseUrl}/api/v1/Auth/ChangePassword`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(
      {
        currentPassword: password,
        newPassword: newPassword,
        confirmPassword: newPassword2
      }
    )
  });
  const res = await handleResponse(response);
  return res;
}

export const forgotPasswordEmailSend = async (email: string) => {
  const url = `${baseUrl}/api/v1/Auth/ForgotPasswordEmailSend`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        email
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const forgotPasswordSend = async (token: string, userId: string, newPassword: string, newPassword2: string) => {
  const url = `${baseUrl}/api/v1/Auth/ForgotPasswordSend`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        userId: userId,
        token: token,
        password: newPassword,
        confirmPassword: newPassword2
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const confirmEmail = async (token: string, userId: string) => {
  const url = `${baseUrl}/api/v1/Auth/ConfirmEmail`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        token,
        userId
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}


