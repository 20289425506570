import { Box, Button } from '@mui/material'
import MedicalAnamnesis from '../components/MedicalAnamnesis'
import SmileDesign from '../components/SmileDesign'
import UserPersonalDetail from '../components/UserPersonalDetail'
import { Diagnosis } from '../utils/types'
import PrintDiagnoses from './PrintDiagnoses'
import { Helmet } from "react-helmet";

type PrintableProfileProps = {
  user: any
}

export default function PrintableProfile({ user }: PrintableProfileProps) {

  const handleCreatePdf = () => {
    window.print();
  }

  return (
    <div>
      <Helmet
        title={`${user.firstName} ${user.lastName}`}
      />
      <Button
        variant="contained"
        onClick={handleCreatePdf}
        sx={{
          alignSelf: 'flex-end',
          m: 2,
        }}
      >
        PDF Oluştur
      </Button>
      <Box component="section" id="printableSection">
        <Box
          sx={{
            mt: 2,
            p: 2,
            px: 6,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: 6
          }}
        >
          {
            user ? (
              <>
                <UserPersonalDetail
                  user={user}
                  mode="print"
                />
                <MedicalAnamnesis
                  user={user}
                />
                <PrintDiagnoses
                  diagnoses={user.diagnosis as Diagnosis[]}
                />
                <SmileDesign
                  user={user}
                  printMode
                />
              </>
            ) : (
              <div>Kullanıcı bulunamadı</div>
            )
          }
        </Box>
      </Box>
    </div>
  );
}