import React from 'react'
import { Clinic, Expertise } from '../utils/types';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import clinicPic from '../assets/clinic-pic.png';
import CustomButton from './CustomButton';
import { clinicExpertises } from '../utils/defaults';
import { useNavigate } from 'react-router-dom';

type ClinicTableRowItemProps = {
  item: Clinic;
};

export default function ClinicTableRowItem({ item }: ClinicTableRowItemProps) {

  const nav = useNavigate();

  const expertises = clinicExpertises.filter(expertise => {
    return item[`${expertise.value as Expertise}`];
  });

  return (
    <TableRow>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img style={{ height: 24, width: 24 }} src={clinicPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>
            {item.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {expertises.map((expertise, index) => (
            <Typography
              key={index}
              style={{
                fontSize: 12,
              }}
            >
              {expertise.label}
            </Typography>
          ))}
        </Box>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>
          {item.phone}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {item.city} / {item.country}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            justifyContent: "flex-end",
            mx: 1,
            py: 0.5,
          }}
        >
          <CustomButton
            label="Düzenle"
            variant="contained"
            size="small"
            onClick={() => {
              nav(`/clinics/${item.id}/edit`);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  )
}
