import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Appointment, Clinic, Expertise } from '../utils/types'
import ClinicAutocomplete from './ClinicAutocomplete'
import { clinicExpertises } from '../utils/defaults'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type AddEditAppointmentDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (date: string, clinic: Clinic, expertises: any, description: string) => void;
  appointment?: Appointment;
}

export default function AddEditAppointmentDialog({ open, onClose, onSubmit, appointment }: AddEditAppointmentDialogProps) {

  const [selectedDate, setSelectedData] = React.useState<Dayjs | null>();
  const [selectedClinic, setSelectedClinic] = React.useState<Clinic | null>(null);
  const [description, setDescription] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [expertises, setExpertises] = useState<any>([]);

  useEffect(() => {
    if (appointment) {
      setSelectedData(dayjs(appointment.date));
      setSelectedClinic(appointment.clinic);
      setDescription(appointment.description || '');
      const expertises = clinicExpertises.filter(expertise => {
        return appointment[`${expertise.value as Expertise}`];
      });
      setExpertises(expertises);
    }
  }, [appointment])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedDate || !selectedClinic || expertises.length === 0) {
      setError('Tarih ve Saat, Klinik ve Uzmanlık alanları zorunludur.');
      return;
    }
    setError('');
    //data must be iso string
    onSubmit(selectedDate.toISOString(), selectedClinic, expertises, description);
  }

  useEffect(() => {
    if (!open) {
      setSelectedData(null);
      setSelectedClinic(null);
      setDescription('');
      setExpertises([]);
      setError('');
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>Yeni Randevu Ekle</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2
          }}
        >
          <DateTimePicker
            value={selectedDate}
            onChange={setSelectedData}
            label="Tarih ve Saat Seçiniz"
          />
          <ClinicAutocomplete
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={clinicExpertises}
            disableCloseOnSelect
            value={expertises}
            onChange={(event, newValue) => {
              setExpertises(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Uzmanlık" placeholder="Uzmanlık" />
            )}
          />
          <TextField
            label="Açıklama"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            size="small"
            fullWidth
            multiline
            rows={4}
          />
        </Box>
        {error && <Box sx={{ color: 'red' }}>{error}</Box>}
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}
        >
          İptal
        </Button>
        <Button
          variant='contained'
          onClick={handleSubmit}
        >
          Kaydet
        </Button>
      </DialogActions>
    </Dialog >
  )
}
