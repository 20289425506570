import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider';
import { jwtDecode } from 'jwt-decode';
import { User } from '../utils/types';
import { getProlife } from '../services/AppServices';
import PrintableProfile from '../components/PrintableProfile';

export default function ProfileForClinic() {

  const loc = useLocation();
  const { token: tokenData } = useContext(AuthContext);
  const [token, setToken] = useState("");
  const [profile, setProfile] = useState<User>();
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();

  const fetchProfile = async () => {
    try {
      const decoded: any = jwtDecode(token);
      if (tokenData) {
        nav(`/users/${decoded.profileId}`);
        return;
      }
      const res = await getProlife(decoded.profileId, true, token);
      setProfile(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token)
      fetchProfile();
  }, [token])

  useEffect(() => {
    const search = new URLSearchParams(loc.search);
    const tokenData = search.get('token');
    if (tokenData) {
      setToken(tokenData);
    }
  }, [])

  if (!token) return null;

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <PrintableProfile
        user={profile}
      />
    </div>
  )
}
