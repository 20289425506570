import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import userPic from "../assets/user-pic.png";
import { gendersMap } from "../utils/defaults";
import { Gender, HealthTourismStatus, InterviewStatus, User } from "../utils/types";
import CustomButton from "./CustomButton";
import { useState } from 'react';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { formatDate } from '../utils/global';

type UsersTableRowItemProps = {
  handleClickAddAppointment: () => void;
  item: User;
  handleChangeStatus: (id: number, status: InterviewStatus, type: string) => void;
  handleClickNotification: (id: number) => void;
  type?: string;
};

export default function UsersTableRowItem({ type, item, handleClickAddAppointment, handleChangeStatus, handleClickNotification }: UsersTableRowItemProps) {

  const nav = useNavigate();
  const loc = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [healthTourismAnchorEl, setHealthTourismAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openHealthTourism = Boolean(healthTourismAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickHealthTourism = (event: React.MouseEvent<HTMLDivElement>) => {
    setHealthTourismAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClickHealthTourismClose = () => {
    setHealthTourismAnchorEl(null);
  }

  return (
    <TableRow>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img style={{ height: 24, width: 24 }} src={userPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>
            {item.firstName} {item.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography style={{ fontSize: 12 }}>
            {item.email}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography style={{ fontSize: 12 }}>
            {item.phone}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>
          {gendersMap[`${item.gender as Gender}`]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {item.city} / {item.country}
        </Typography>
      </TableCell>
      {type === "healthTourism" && (
        <TableCell>
          {item.healthTourismDate && formatDate(item.healthTourismDate)}
        </TableCell>
      )}
      {type === "interviewStatus" && (
        <TableCell>
          {item.interviewDate && formatDate(item.interviewDate)}
        </TableCell>
      )}
      {type !== 'interviewStatus' && (
        <TableCell align="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleClickHealthTourism}
          >
            {
              item.healthTourism !== HealthTourismStatus.WAITING ?
                <CheckCircleIcon sx={{ color: '#FF850A' }} />
                :
                <CancelIcon sx={{ color: '#FF850A' }} />
            }
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={healthTourismAnchorEl}
            open={openHealthTourism}
            onClose={handleClickHealthTourismClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {item.healthTourism !== HealthTourismStatus.WAITING && (<MenuItem onClick={() => { handleChangeStatus(item.id, InterviewStatus.WAITING, "healthTourism"); setHealthTourismAnchorEl(null) }}>Görüşülmedi</MenuItem>)}
            {item.healthTourism === HealthTourismStatus.WAITING && (<MenuItem onClick={() => { handleChangeStatus(item.id, InterviewStatus.COMPLETED, "healthTourism"); setHealthTourismAnchorEl(null) }}>Görüşüldü</MenuItem>)}
          </Menu>
        </TableCell>
      )}
      {type !== 'healthTourism' && (
        <TableCell align="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            {
              item.interviewStatus !== InterviewStatus.WAITING ?
                <CheckCircleIcon color="success" />
                :
                <CancelIcon color="success" />
            }
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {item.interviewStatus !== InterviewStatus.WAITING && (<MenuItem onClick={() => { handleChangeStatus(item.id, InterviewStatus.WAITING, "interviewStatus"); setAnchorEl(null) }}>Görüşülmedi</MenuItem>)}
            {item.interviewStatus === InterviewStatus.WAITING && (<MenuItem onClick={() => { handleChangeStatus(item.id, InterviewStatus.COMPLETED, "interviewStatus"); setAnchorEl(null) }}>Görüşüldü</MenuItem>)}
          </Menu>
        </TableCell>)}
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            justifyContent: "flex-end",
            mx: 1,
            py: 0.5,
          }}
        >
          <CustomButton
            label="Detay Göster"
            variant="outlined"
            size="small"
            onClick={() => { nav(`/users/${item.id}`) }}
          />
          <CustomButton
            label="Randevu Ekle"
            variant="contained"
            size="small"
            onClick={() => { handleClickAddAppointment() }}
          />
          <IconButton
            onClick={() => handleClickNotification(item.id)}
            sx={{
              color: 'primary.main'
            }}
          >
            <CircleNotificationsIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
