import { Add } from '@mui/icons-material';
import { Box, Button, FormControl, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import ClinicTableRowItem from './ClinicTableRowItem';
import { Clinic } from '../utils/types';

type ClinicsTableProps = {
  clinics: Clinic[];
  count: number;
  recordPerPage: number;
  setRecordPerPage: (value: number) => void;
  page: number;
  setPage: (value: number) => void;
  search: string;
  setSearch: (value: string) => void;
}

export default function ClinicsTable({ clinics, count, recordPerPage, setRecordPerPage, page, setPage, search, setSearch }: ClinicsTableProps) {

  const nav = useNavigate();

  return (
    <Box>
      <Paper
        sx={{
          py: 3,
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            p: 2
          }}
        >
          <TextField
            label="Ara"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
          <Box sx={{ width: 220 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recordPerPage}
                onChange={(e) => setRecordPerPage(e.target.value as number)}
                size='small'
              >
                <MenuItem value={10}>10 Kayıt Listele</MenuItem>
                <MenuItem value={25}>25 Kayıt Listele</MenuItem>
                <MenuItem value={50}>50 Kayıt Listele</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            variant='contained'
            onClick={() => { nav('/clinics/new') }}
            sx={{
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            <Add sx={{ fontSize: 16 }} />
            Klinik Ekle
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%'
          }}
        >
          <TableContainer
            style={{
              overflowX: 'initial',
            }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead
                sx={{
                  '& th': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontSize: 12 }}>KLİNİK ADI</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 12 }}>
                      BRANŞLAR
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 12 }}>TELEFON</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 12 }}>LOKASYON</Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography sx={{ fontSize: 12 }}>İŞLEMLER</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  clinics.map((item, index) => (
                    <ClinicTableRowItem
                      key={index}
                      item={item}
                    />
                  ))
                }
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 10,
                width: '100%',
              }}
            >
            </Box>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
            px: 2
          }}
        >
          <Typography>
            {count} kayıt bulundu
          </Typography>
          <Pagination
            count={Math.ceil(count / recordPerPage)}
            page={page}
            onChange={(e, value) => setPage(value)}
            size="small"
          />
        </Box>
      </Paper>
    </Box>
  )
}
