import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import userPic from "../assets/user-pic.png";
import { gendersMap } from "../utils/defaults";
import { AppUser, Gender, HealthTourismStatus, InterviewStatus, Role, User } from "../utils/types";
import CustomButton from "./CustomButton";
import { useState } from 'react';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { Delete, Key } from '@mui/icons-material';

type AdminUsersTableRowItemProps = {
  item: AppUser;
  handleChangeRole: (id: number, role: Role) => void;
  handlePasswordReset: (id: number) => void;
  handleDeleteUser: (id: number) => void;
};

export default function AdminUsersTableRowItem({ item, handleChangeRole, handlePasswordReset, handleDeleteUser }: AdminUsersTableRowItemProps) {

  const nav = useNavigate();
  const loc = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <TableRow>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img style={{ height: 24, width: 24 }} src={userPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>
            {item.firstName} {item.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography style={{ fontSize: 12 }}>
            {item.email}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            {item.role === Role.SUPERADMIN && <Typography sx={{ fontSize: 12 }}>Süper Admin</Typography>}
            {item.role === Role.ADMIN && <Typography sx={{ fontSize: 12 }}>Admin</Typography>}
            {item.role === Role.APPADMIN && <Typography sx={{ fontSize: 12 }}>App Admin</Typography>}
            {item.role === Role.USER && <Typography sx={{ fontSize: 12 }}>Kullanıcı</Typography>}
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => { handleChangeRole(item.id, Role.SUPERADMIN); setAnchorEl(null) }}>Süper Admin</MenuItem>
            <MenuItem onClick={() => { handleChangeRole(item.id, Role.ADMIN); setAnchorEl(null) }}>Admin</MenuItem>
            <MenuItem onClick={() => { handleChangeRole(item.id, Role.APPADMIN); setAnchorEl(null) }}>App Admin</MenuItem>
            <MenuItem onClick={() => { handleChangeRole(item.id, Role.USER); setAnchorEl(null) }}>Kullanıcı</MenuItem>
          </Menu>
          {(item.role === Role.SUPERADMIN || item.role === Role.ADMIN) && (
            <IconButton
              size="small"
              onClick={() => { handlePasswordReset(item.id) }}
            >
              <Key />
            </IconButton>
          )}
          {(item.role === Role.ADMIN) && (
            <IconButton
              size="small"
              onClick={() => { handleDeleteUser(item.id) }}
            >
              <Delete />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
