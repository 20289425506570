import React, { useContext, useEffect } from 'react'
import { Clinic } from '../utils/types'
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { AuthContext } from '../contexts/AuthProvider';
import { baseUrl } from '../utils/constants';

type ClinicAutocompleteProps = {
  selectedClinic: Clinic | null;
  setSelectedClinic: (clinic: Clinic | null) => void;
}

export default function ClinicAutocomplete({ selectedClinic, setSelectedClinic }: ClinicAutocompleteProps) {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Clinic[]>([]);
  const loading = open && options.length === 0;
  const { token } = useContext(AuthContext);
  const [search, setSearch] = React.useState<string>('');

  const fetchClinics = async () => {
    try {
      const res = await fetch(`${baseUrl}/clinics?skip=0&take=20&search=${search}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = await res.json();
      setOptions(data.results);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchClinics();
  },[search])
  

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onInputChange={(e, value) => setSearch(value)}
      onChange={(e, value) => setSelectedClinic(value)}
      value={selectedClinic}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Klinik Seçiniz"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
