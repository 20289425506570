export const gendersMap = {
  "MALE": "Erkek",
  "FEMALE": "Kadın",
  "OTHER": "Diğer"
}

export const clinicExpertiseMap = {
  "pedodontics": "Pedodonti",
  "periodontology": "Periodontoloji",
  "surgery": "Cerrahi",
  "restorative": "Restoratif",
  "endodontics": "Endodonti",
  "protetics": "Protez",
  "orthodontics": "Ortodonti",
  "radiologyGeneral": "Genel Radyoloji"
}


export const clinicExpertises = [
  {
    value: "pedodontics",
    label: "Pedodonti"
  },
  {
    value: "periodontology",
    label: "Periodontoloji"
  },
  {
    value: "surgery",
    label: "Cerrahi"
  },
  {
    value: "restorative",
    label: "Restoratif"
  },
  {
    value: "endodontics",
    label: "Endodonti"
  },
  {
    value: "protetics",
    label: "Protez"
  },
  {
    value: "orthodontics",
    label: "Ortodonti"
  },
  {
    value: "radiologyGeneral",
    label: "Genel Radyoloji"
  }
]