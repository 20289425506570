import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import UserDetail from '../pages/UserDetail';

type UserProfileDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit?: () => void
  userId: number
}
export default function UserProfileDialog({ open, onClose, onSubmit, userId }: UserProfileDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Profil Paylaşımı
          </Typography>
          <Button autoFocus color="inherit" onClick={onSubmit}>
            Tamam
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <UserDetail userId={userId.toString()} />
      </DialogContent>
    </Dialog>
  )
}
