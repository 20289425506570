import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import userPic from "../assets/user-pic.png";
import React from 'react'
import { Gender, User } from '../utils/types';
import { gendersMap } from '../utils/defaults';
import dayjs from 'dayjs';

type UserPersonalDetailProps = {
  user: User;
  handleClickShareProfile?: () => void;
  handleDownloadProfile?: () => void;
  mode?: string
}

export default function UserPersonalDetail({ user, handleClickShareProfile, handleDownloadProfile, mode }: UserPersonalDetailProps) {

  const isChild = new Date().getFullYear() - new Date(user.birthDate).getFullYear() < 18;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: 2,
            gap: 2
          }}
        >
          <Avatar
            sx={{
              width: 100,
              height: 100
            }}
            alt="Remy Sharp"
            src={userPic}
          />
          <Box>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#32353E'
              }}
            >
              {user.firstName} {user.lastName} - ({isChild ? "Çocuk" : "Yetişkin"})
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        {handleClickShareProfile && (
          <Box
            sx={{
              display: 'flex',
              gap: 1
            }}
          >
            <Button
              onClick={handleClickShareProfile}
              variant='outlined'
              sx={{
                textTransform: 'none',
              }}
            >
              Profili Paylaş
            </Button>
            <Button
              onClick={handleDownloadProfile}
              variant='outlined'
              sx={{
                textTransform: 'none',
              }}
            >
              Profili İndir
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <DetailItem title="Telefon" value={user.phone} />
              <DetailItem title="Cinsiyet" value={gendersMap[`${user.gender as Gender}`]} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <DetailItem title="Doğum Tarihi" value={dayjs(user.birthDate).format('DD/MM/YYYY')} />
              <DetailItem title="Adres" value={`${user.district}/${user.city} - ${user.country}`} />
            </Box>
          </Grid>
          {(user.relation !== 'HIMHERSELF' && mode !== 'print') && (
            <Grid item xs={12}>
              <DetailItem title="Bağlı Hesap" value={user.user.firstName + " " + user.user.lastName} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box >
  )
}

function DetailItem({ title, value }: { title: string, value: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography
        sx={{
          fontSize: 18,
          color: '#32353E',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#EFEFEF',
          color: '#A3AED0',
          p: 2,
          borderRadius: '10px',
        }}
      >
        <Typography>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
