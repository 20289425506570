import { IconButton } from '@mui/material';
import React from 'react'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
type OrderByIconButtonProps = {
  direction: string;
  onClick: () => void;
}

export default function OrderByIconButton({ direction, onClick }: OrderByIconButtonProps) {
  return (
    <IconButton
      onClick={onClick}
      size="small"
      sx={{
        p: 0,
        ml: 1,
        color: 'text.secondary'
      }}
    >
      {direction === 'asc' ? <NorthIcon sx={{ fontSize: 14 }} /> : <SouthIcon sx={{ fontSize: 14 }} />}
    </IconButton>
  )
}
