import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Appointment, Clinic } from '../utils/types';
import CustomButton from './CustomButton';
import userPic from "../assets/user-pic.png"
import AddEditAppointmentDialog from './AddEditAppointmentDialog';
import { useContext, useState } from 'react';
import { updateAppointment } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthProvider';
import { useAlert } from '../utils/useAlert';
import { useNavigate } from 'react-router-dom';

type LastAppointmentsProps = {
  appointments: Appointment[];
  setLastAppointments: (appointments: Appointment[]) => void
}

export default function LastAppointments({ appointments, setLastAppointments }: LastAppointmentsProps) {

  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();
  const nav = useNavigate();

  const handleClickEdit = (item: Appointment) => {
    setSelectedAppointment(item);
    setOpen(true);
  }

  const onClose = () => {
    setSelectedAppointment(undefined);
    setOpen(false);
  }


  const onSubmit = async (date: string, clinic: Clinic, expertises: any, description: string) => {
    try {
      const newAppointment = {
        profileId: selectedAppointment?.profile.id,
        date,
        clinicId: clinic.id,
        description,
        pedodontics: expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
        periodontology: expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
        surgery: expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
        restorative: expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
        endodontics: expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
        protetics: expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
        orthodontics: expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
        radiologyGeneral: expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
      };

      const res = await updateAppointment(selectedAppointment?.id!, newAppointment, token);
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === res.id) {
          return res;
        }
        return appointment;
      });
      setLastAppointments(updatedAppointments);
      showSnackbar('Randevu başarıyla güncellendi', 'success');
      setOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  return (
    <Box
      sx={{
        mt: 3,
        p: 2,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Son Randevular
        </Typography>
        <Button
          onClick={() => { nav('/appointments') }}
          sx={{
            textTransform: 'none',
          }}
        >
          Tümünü Gör
        </Button>
      </Box>

      <TableContainer
        style={{
          overflowX: 'initial',
        }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead
            sx={{
              '& th': {
                backgroundColor: 'transparent !important'
              }
            }}
          >
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 12 }}>HASTA ADI</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 12 }}>KLİNİK ADI</Typography>
                </Box>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={{ fontSize: 12 }}>İŞLEMLER</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              appointments.map((item, index) => (
                <TableRow>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <img style={{ height: 24, width: 24 }} src={item.profile?.image || userPic} alt="user" />
                      <Typography style={{ fontSize: 12 }}>
                        {item.profile.firstName} {item.profile.lastName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {item.clinic.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <CustomButton
                      label="Düzenle"
                      variant="contained"
                      size="small"
                      sx={{
                        mx: 'auto'
                      }}
                      onClick={() => { handleClickEdit(item) }}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 10,
            width: '100%',
          }}
        >
        </Box>
      </TableContainer>
      <AddEditAppointmentDialog
        open={open}
        onClose={onClose}
        appointment={selectedAppointment}
        onSubmit={onSubmit}
      />
    </Box>
  )
}
function showSnackbar(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

