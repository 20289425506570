import React from 'react'
import { Diagnosis, Expertise } from '../utils/types'
import { Box, Divider, Typography } from '@mui/material';
import { DiagnosisData } from './DentalAnamnesisTableRowItem';
import { clinicExpertises } from '../utils/defaults';

type PrintDiagnosesProps = {
  diagnoses: Diagnosis[];
}

export default function PrintDiagnoses({ diagnoses }: PrintDiagnosesProps) {


  function formatDiagnosis(jsonString: string): string[] {
    const parsedData: DiagnosisData = JSON.parse(jsonString);
    const groupedDiagnosis = parsedData.diagnosis.reduce((acc, item) => {
      const [key, value] = Object.entries(item)[0];
      acc[key] = acc[key] || [];
      if (!acc[key].includes(value)) {
        acc[key].push(value);
      }
      return acc;
    }, {} as { [key: string]: number[] });

    return Object.entries(groupedDiagnosis)
      .map(([key, values]) => `${key}: ${values.join(', ')}`);
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: 600,
          color: '#32353E',
          'page-break-before': 'always'
        }}
      >
        Dental Anamnez
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          color: '#9DA0AF',
        }}
      >
        Burada kullanıcının doldurduğu dental anemnez bilgileri yer almaktadır
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          mt: 4
        }}
      >
        {diagnoses.map((item, index) => {
          const expertises = clinicExpertises.filter(expertise => {
            return item[`${expertise.value as Expertise}`];
          });
          const answers = item.userAnswers ? JSON.parse(item.userAnswers) : [];

          return (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
                key={index}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: '#32353E',
                    'page-break-before': index > 0 ? 'always' : 'auto'
                  }}
                >
                  {index + 1}. Dental Anamnez
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Tarih: <span style={{ fontWeight: 500 }}>{new Date(item.createdAt).toLocaleDateString()}</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Tanı
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {item.aiDiagnosis
                        ? formatDiagnosis(item.aiDiagnosis).map((diagnosis, index) => (
                          <Typography
                            key={index}
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            {diagnosis}
                          </Typography>
                        ))
                        : item.dentalAnamnesis || 'Tanı Bulunamadı'}
                    </Typography>
                  </Box>
                  {Array.isArray(answers.userAnswers) && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: 'black',
                          fontWeight: 600
                        }}
                      >
                        Anemnez Soruları
                      </Typography>
                      <Box>
                        {answers.userAnswers.map((answer: any, index: number) => (
                          <Typography
                            key={index}
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            {answer.questionText}: <strong>{answer.answerText}</strong>
                          </Typography>
                        ))}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: 'black',
                          fontWeight: 600
                        }}
                      >
                        Anemnez Cevabı:
                      </Typography>
                      <Typography
                        key={index}
                        sx={{
                          fontSize: 14,
                        }}
                      >{item.dentalAnamnesis}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Şikayet :<span style={{ fontWeight: 500 }}> {item.complaint}</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Alan
                  </Typography>
                  <Box>
                    {expertises.map((department, index) => (
                      <Typography
                        key={index}
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {department.label}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Ağız İçi Fotoğraflar
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      mt: 1

                    }}
                  >
                    {item.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt="Ağız içi fotoğraf"
                        style={{ width: 200, borderRadius: 10, margin: 2 }}
                      />
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    'page-break-before': 'always'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Doktor Rapor
                  </Typography>
                  <img
                    src={item.doctorReport}
                    alt="Doktor raporu"
                    style={{ maxWidth: '190mm', borderRadius: 10, margin: 2 }}
                  />
                </Box>
                <Box
                  sx={{
                    'page-break-before': 'always'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 24,
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Hasta Rapor
                  </Typography>
                  <img
                    src={item.result}
                    alt="Doktor raporu"
                    style={{ maxWidth: '190mm', borderRadius: 10, margin: 2 }}
                  />
                </Box>
              </Box>
              <Divider />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
