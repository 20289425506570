import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import { useFetcher } from 'react-router-dom';
import { getLastAppointments, getLastHealthTourism, getLastInterviews, getMetrics } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthProvider';
import MetricBox from '../components/MetricBox';
import { ApiList, Appointment, Metrics, User } from '../utils/types';
import LastAppointments from '../components/LastAppointments';
import LastInterviews from '../components/LastInterviews';
import LastHealthTourism from '../components/LastHealthTourism';

export default function Dashboard() {

  const [metrics, setMetrics] = React.useState<Metrics>();
  const [lastAppointments, setLastAppointments] = React.useState<Appointment[]>([]);
  const [lastInterviews, setLastInterviews] = React.useState<User[]>([]);
  const [lastHealthTourism, setLastHealthTourism] = React.useState<User[]>([]);
  const { token } = useContext(AuthContext);

  const fetchData = async () => {
    try {
      const metricsPromise = getMetrics(token);
      const lastAppointmentsPromise = getLastAppointments(token);
      const lastInterviewsPromise = getLastInterviews(token);
      const lastHealthTourismPromise = getLastHealthTourism(token);
      const [metricsData, lastAppointmentsData, lastInterviewsData, lastHealthTourismData] = await Promise.all([metricsPromise, lastAppointmentsPromise, lastInterviewsPromise, lastHealthTourismPromise]);
      setMetrics(metricsData);
      setLastAppointments(lastAppointmentsData.results);
      setLastInterviews(lastInterviewsData.results);
      setLastHealthTourism(lastHealthTourismData.results);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Anasayfa' },
        ]}
        title="Anasayfa"
      />
      <Box
        sx={{
          mt: 3,
          p: 2,
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
        }}
      >
        {metrics && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <MetricBox title="Toplam Profil" value={metrics.totalProfiles} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MetricBox title="Toplam Randevu" value={metrics.totalAppointments} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MetricBox title="Toplam Tanı" value={metrics.totalDiagnosticReports} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MetricBox title="Toplam Gülüş Tasarımı" value={metrics.totalSmileDesigns} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <LastInterviews users={lastInterviews} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <LastHealthTourism users={lastHealthTourism} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <LastAppointments appointments={lastAppointments} setLastAppointments={setLastAppointments} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
