import { CircleNotifications } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthProvider';
import { addAppointment, updateHealthTourism, updateInterviewStatus, updateUser } from '../services/AppServices';
import { Clinic, InterviewStatus, User } from '../utils/types';
import { useAlert } from '../utils/useAlert';
import AddEditAppointmentDialog from './AddEditAppointmentDialog';
import OrderByIconButton from './OrderByIconButton';
import UsersTableRowItem from './UsersTableRowItem';
import UserProfileDialog from './UserProfileDialog';

type UsersTableProps = {
  users: User[];
  count: number;
  recordPerPage: number;
  setRecordPerPage: (value: number) => void;
  page: number;
  setPage: (value: number) => void;
  search: string;
  setSearch: (value: string) => void;
  orderBy: string;
  setOrderBy: (value: string) => void;
  handleClickNotification: (id?: number) => void;
  handleMutate: () => void;
  type?: string;
  archive?: boolean;
  setArchive?: (value: boolean) => void;
}

export default function UsersTable({ archive, setArchive, type, handleMutate, handleClickNotification, orderBy, setOrderBy, users, count, recordPerPage, setRecordPerPage, page, setPage, search, setSearch }: UsersTableProps) {

  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [nameOrder, setNameOrder] = useState('asc');
  const [genderOrder, setGenderOrder] = useState('asc');
  const [interviewStatusOrder, setInterviewStatusOrder] = useState('desc');
  const [healthTourismOrder, setHealthTourismOrder] = useState('desc');
  const [healthTourismDateOrder, setHealthTourismDateOrder] = useState('desc');
  const [interviewDateOrder, setInterviewDateOrder] = useState('desc');
  const [locationOrder, setLocationOrder] = useState('asc');
  const [shareProfileDialogOpen, setShareProfileDialogOpen] = useState(false);
  const [appointmentInput, setAppointmentInput] = useState<any>();
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();

  const handleClickAddAppointment = (user: User) => {
    setSelectedUser(user);
    setOpenAppointmentDialog(true);
  }

  const handleAccepAppointment = async (date: string, clinic: Clinic, expertises: any, description: string) => {
    setAppointmentInput({
      date,
      clinic,
      expertises,
      description
    });
    setShareProfileDialogOpen(true);
  }

  const handleAddAppointment = async () => {
    const { date, clinic, expertises, description } = appointmentInput;
    try {
      const newAppointment = {
        profileId: selectedUser?.id,
        date,
        clinicId: clinic.id,
        description,
        pedodontics: expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
        periodontology: expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
        surgery: expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
        restorative: expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
        endodontics: expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
        protetics: expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
        orthodontics: expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
        radiologyGeneral: expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
      };
      const res = await addAppointment(newAppointment, token);
      setOpenAppointmentDialog(false);
      setShareProfileDialogOpen(false);
      setSelectedUser(null);
      setAppointmentInput(null);
      showSnackbar('Randevu başarıyla oluşturuldu', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Randevu oluşturulurken bir hata oluştu', 'error');
    }
  }

  const handleChangeStatus = async (id: number, status: InterviewStatus, type: string) => {

    try {
      if (type === 'interviewStatus') {
        const res = await updateInterviewStatus(id, status, token);
        showSnackbar('Görüşme durumu başarıyla güncellendi', 'success');
      } else {
        const res = await updateHealthTourism(id, status, token);
        showSnackbar('Sağlık turizmi durumu başarıyla güncellendi', 'success');
      }
      handleMutate();
    } catch (error) {
      console.log(error);
      showSnackbar('Görüşme durumu güncellenirken bir hata oluştu', 'error');
    }
  }

  const handleOrderBy = (value: string) => {
    if (orderBy === `${value}:asc`) {
      setOrderBy(`${value}:desc`);
      if (value === 'firstName') {
        setNameOrder('desc');
      } else if (value === 'gender') {
        setGenderOrder('desc');
      } else if (value === 'interviewStatus') {
        setInterviewStatusOrder('desc');
      } else if (value === 'healthTourism') {
        setHealthTourismOrder('desc');
      } else if (value === 'city') {
        setLocationOrder('desc');
      } else if (value === 'healthTourismDate') {
        setHealthTourismDateOrder('desc');
      } else if (value === 'interviewDate') {
        setInterviewDateOrder('desc');
      }
    } else {
      setOrderBy(`${value}:asc`);
      if (value === 'firstName') {
        setNameOrder('asc');
      } else if (value === 'gender') {
        setGenderOrder('asc');
      } else if (value === 'interviewStatus') {
        setInterviewStatusOrder('asc');
      } else if (value === 'healthTourism') {
        setHealthTourismOrder('asc');
      } else if (value === 'city') {
        setLocationOrder('asc');
      } else if (value === 'healthTourismDate') {
        setHealthTourismDateOrder('asc');
      } else if (value === 'interviewDate') {
        setInterviewDateOrder('asc');
      }
    }
  }

  return (
    <Box>
      <Paper
        sx={{
          py: 3,
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            p: 2
          }}
        >
          <TextField
            label="Ara"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
          <Box sx={{ width: 220 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recordPerPage}
                onChange={(e) => setRecordPerPage(e.target.value as number)}
                size='small'
              >
                <MenuItem value={10}>10 Kayıt Listele</MenuItem>
                <MenuItem value={25}>25 Kayıt Listele</MenuItem>
                <MenuItem value={50}>50 Kayıt Listele</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {!type && (
            <IconButton
              onClick={() => handleClickNotification()}
              sx={{
                color: 'primary.main'
              }}
            >
              <CircleNotifications />
            </IconButton>
          )}
          {setArchive && (
            <FormControlLabel
              control={
                <Switch
                  checked={archive || false}
                  onChange={(e) => setArchive && setArchive(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Arşiv"
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%'
          }}
        >
          <TableContainer
            style={{
              overflowX: 'initial',
            }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead
                sx={{
                  '& th': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>ADI SOYADI</Typography>
                      <OrderByIconButton direction={nameOrder} onClick={() => { handleOrderBy('firstName') }} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>EMAİL</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: 12 }}>
                      TELEFON
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>CİNSİYET</Typography>
                      <OrderByIconButton direction={genderOrder} onClick={() => { handleOrderBy('gender') }} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>LOKASYON</Typography>
                      <OrderByIconButton direction={locationOrder} onClick={() => { handleOrderBy('city') }} />
                    </Box>
                  </TableCell>
                  {type && (<TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>İSTEK TARİHİ</Typography>
                      <OrderByIconButton direction={type === 'healthTourism' ? healthTourismDateOrder : interviewDateOrder} onClick={() => { handleOrderBy(type === 'healthTourism' ? 'healthTourismDate' : 'interviewDate') }} />
                    </Box>
                  </TableCell>)}
                  {type !== 'interviewStatus' && (<TableCell align='center'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>SAĞLIK TURİZMİ</Typography>
                      <OrderByIconButton direction={healthTourismOrder} onClick={() => { handleOrderBy('healthTourism') }} />
                    </Box>
                  </TableCell>)}
                  {type !== 'healthTourism' && (<TableCell align='center'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>GÖRÜŞME TALEBİ</Typography>
                      <OrderByIconButton direction={interviewStatusOrder} onClick={() => { handleOrderBy('interviewStatus') }} />
                    </Box>
                  </TableCell>)}
                  <TableCell align='center'>
                    <Typography sx={{ fontSize: 12 }}>İŞLEMLER</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.map((item, index) => (
                    <UsersTableRowItem
                      key={index}
                      handleChangeStatus={handleChangeStatus}
                      handleClickAddAppointment={() => { handleClickAddAppointment(item) }}
                      item={item}
                      handleClickNotification={handleClickNotification}
                      type={type}
                    />
                  ))
                }
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 10,
                width: '100%',
              }}
            >
            </Box>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
            px: 2
          }}
        >
          <Typography>
            {count} kayıt bulundu
          </Typography>
          <Pagination
            count={Math.ceil(count / recordPerPage)}
            page={page}
            onChange={(e, value) => setPage(value)}
            size="small"
          />
        </Box>
        <AddEditAppointmentDialog
          open={openAppointmentDialog}
          onClose={() => setOpenAppointmentDialog(false)}
          onSubmit={handleAccepAppointment}
        />
      </Paper>
      {selectedUser && (
        <UserProfileDialog
          open={shareProfileDialogOpen}
          onClose={() => setShareProfileDialogOpen(false)}
          onSubmit={() => handleAddAppointment()}
          userId={selectedUser?.id!}
        />
      )}
    </Box>
  )
}
