export type ApiList<T> = {
  results: T[],
  count: number,
};

export type BreadCrumbItem = {
  label: string;
  to?: string;
};

export type Metrics = {
  totalAppointments: number,
  totalProfiles: number,
  totalDiagnosticReports: number,
  totalSmileDesigns: number,
}

export type TableHeadType = {
  label: string;
  width?: string;
  align?: 'left' | 'right' | 'center';
}

export type AppUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
}


export type User = {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  phone: string;
  birthDate: string;
  medicalAnamnesis: string[];
  gender: Gender;
  image?: string;
  city: string;
  district: string;
  country: string;
  diagnosis: Diagnosis[];
  smileDesign: SmileDesign[];
  interviewStatus?: InterviewStatus;
  interviewDate: string;
  healthTourism: HealthTourismStatus;
  healthTourismDate: string;
  appointment: Appointment[];
  user: AppUser,
  relation: string
}

export type SmileDesign = {
  id: number;
  profileId: number;
  profile: User;
  image: string;
  selectedStatus?: boolean;
}

// profile          Profile  @relation(fields: [profileId], references: [id])
//   profileId        Int
//   dentalAnamnesis  String
//   complaint        String   @default("")
//   images           String[]
//   xray             String?
//   result           String?
//   doctorReport     String?
//   pedodontics      Boolean  @default(false)
//   periodontology   Boolean  @default(false)
//   surgery          Boolean  @default(false)
//   restorative      Boolean  @default(false)
//   endodontics      Boolean  @default(false)
//   protetics        Boolean  @default(false)
//   orthodontics     Boolean  @default(false)
//   radiologyGeneral Boolean  @default(false)

export type Diagnosis = {
  id: number;
  createdAt: string;
  profileId: number;
  dentalAnamnesis: string;
  complaint: string;
  images: string[];
  xray?: string;
  result?: string;
  aiDiagnosis?: string;
  doctorReport?: string;
  selectedStatus?: boolean;
  pedodontics: boolean;
  periodontology: boolean;
  surgery: boolean;
  restorative: boolean;
  endodontics: boolean;
  protetics: boolean;
  orthodontics: boolean;
  userAnswers: any;
  radiologyGeneral: boolean;
}

export type Clinic = {
  id: number;
  name: string;
  phone: string;
  locationUrl?: string;
  email?: string;
  city: string;
  country: string;
  district: string;
  pedodontics: boolean;
  periodontology: boolean;
  surgery: boolean;
  restorative: boolean;
  endodontics: boolean;
  protetics: boolean;
  orthodontics: boolean;
  radiologyGeneral: boolean;
};

export type Appointment = {
  id: number;
  profileId: number;
  profile: User;
  date: string;
  clinicId: number;
  clinic: Clinic;
  description?: string;
  pedodontics: boolean;
  periodontology: boolean;
  surgery: boolean;
  restorative: boolean;
  endodontics: boolean;
  protetics: boolean;
  orthodontics: boolean;
  radiologyGeneral: boolean;
  status: AppointmentStatus;
}


export type Campaign = {
  id?: number;
  title: string;
  description?: string;
  image: string;
  link?: string;
  status?: boolean;
  priority: number;
}

export enum Role {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
  APPADMIN = 'APPADMIN',
}

export enum AppointmentStatus {
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

// enum Gender {
//   MALE
//   FEMALE
//   OTHER
// }

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Expertise {
  PEDODONTICS = 'pedodontics',
  PERIODONTOLOGY = 'periodontology',
  SURGERY = 'surgery',
  RESTORATIVE = 'restorative',
  ENDODONTICS = 'endodontics',
  PROSTETICS = 'protetics',
  ORTHODONTICS = 'orthodontics',
  RADIOLOGY_GENERAL = 'radiologyGeneral',
}

export enum InterviewStatus {
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
}

export enum HealthTourismStatus {
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
}