import { CheckBox } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { User } from '../utils/types'

type MedicalAnamnesisProps = {
  user: User
}

export default function MedicalAnamnesis({ user }: MedicalAnamnesisProps) {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: '#32353E'
          }}
        >
          Medikal Anamnez
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            color: '#9DA0AF',
          }}
        >
          Burada kullanıcının doldurduğu medikal anemnez bilgileri yer almaktadır
        </Typography>
      </Box>
      {
        user.medicalAnamnesis.length === 0 && (
          <Typography
            sx={{
              fontSize: 14,
              color: '#9DA0AF',
            }}
          >
            Kullanıcı medikal anamnez bilgisi girmemiş
          </Typography>
        )
      }
      <Box>
        <Grid container spacing={3}>
          {user.medicalAnamnesis.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                <CheckBox color="primary" />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: '#32353E'
                  }}
                >
                  {item}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
