import { Box, Paper } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import UserPersonalDetail from '../components/UserPersonalDetail'
import AppointmentDetail from '../components/AppointmentDetail'
import MedicalAnamnesis from '../components/MedicalAnamnesis'
import DentalAnamnesis from '../components/DentalAnamnesis'
import { baseUrl } from '../utils/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { User } from '../utils/types'
import { fetcher } from '../utils/global'
import { AuthContext } from '../contexts/AuthProvider'
import SmileDesign from '../components/SmileDesign'
import { getShareProfileLink, shareProfileWithClinic, updateDiagnosisStatus, updateSmileDesignStatus } from '../services/AppServices'
import { useAlert } from '../utils/useAlert'
import UserProfileDialog from '../components/UserProfileDialog'
import SelecetClinicDialog from '../components/SelecetClinicDialog'

type UserDetailProps = {
  userId?: string
}

export default function UserDetail({ userId }: UserDetailProps) {

  const { id: profileId } = useParams();
  const id = userId || profileId;
  const { token } = useContext(AuthContext);
  const nav = useNavigate();
  const showSnackbar = useAlert();
  const loc = useLocation();
  const [user, setUser] = React.useState<User>();
  const [shareProfileDialogOpen, setShareProfileDialogOpen] = React.useState(false);
  const [shareProfileMode, setShareProfileMode] = React.useState('');
  const [selectClinicDialogOpen, setSelectClinicDialogOpen] = React.useState(false);

  const handleSubmitSelectClinic = async (clinicId: number) => {
    try {
      const res = await shareProfileWithClinic(clinicId, +id!, token);
      showSnackbar('Profil paylaşıldı', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Profil paylaşılırken bir hata oluştu', 'error');
    } finally {
      setSelectClinicDialogOpen(false);
    }
  }

  const handleClickShareProfile = async () => {
    setSelectClinicDialogOpen(true);
  }

  const handleCopyProfileLink = async () => {
    try {
      const tokenData = await getShareProfileLink(+id!, token);
      const host = window.location.host;
      const res = `${host}/profile-page?token=${tokenData.token}`;
      navigator.clipboard.writeText(res);
      setSelectClinicDialogOpen(false);
      showSnackbar('Profil linki kopyalandı', 'success');
    } catch (error) {
      console.log(error);
    }
  }

  const handleDownloadProfile = (mode: 'download' | 'share') => {
    setShareProfileMode(mode);
    setShareProfileDialogOpen(true);
  }

  const { data: userData, isLoading, error } = useSWR<User>(
    `${baseUrl}/profiles/${id}`,
    (url: string) => fetcher(url, token));

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData])

  const handleUpdateDiagnosesStatus = async (id: number, status: boolean) => {
    try {
      await updateDiagnosisStatus(id, status, token);
      const updatedUser = { ...user! };
      updatedUser.diagnosis = updatedUser.diagnosis.map(item => {
        if (item.id === id) {
          return { ...item, selectedStatus: status }
        }
        return item;
      });
      setUser(updatedUser);
    } catch (error) {
      console.log(error);
      showSnackbar('Durum güncellenirken bir hata oluştu', 'error');
    }
  }

  const handleUpdateSmileDesignStatus = async (id: number, status: boolean) => {
    try {
      await updateSmileDesignStatus(id, status, token);
      const updatedUser = { ...user! };
      updatedUser.smileDesign = updatedUser.smileDesign.map(item => {
        if (item.id === id) {
          return { ...item, selectedStatus: status }
        }
        return item;
      });
      setUser(updatedUser);
    } catch (error) {
      console.log(error);
      showSnackbar('Durum güncellenirken bir hata oluştu', 'error');
    }
  }

  const handleSubmitShareProfile = async () => {
    if (shareProfileMode === 'download') {
      nav(`/users/${id}/download`);
    } else {
      handleClickShareProfile();
    }
    setShareProfileDialogOpen(false);
  }

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error</div>

  return (
    <Box>
      {!userId && (
        <PageHeader
          breadcrumbItems={[
            { label: 'Ana Sayfa', to: '/' },
            { label: 'Kullanıcılar', to: '/users' },
            { label: user?.firstName + " " + user?.lastName },
          ]}
          title="Kullanıcı Detayı"
        />
      )}
      <Paper
        sx={{
          mt: 2,
          p: 2,
          px: 6,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: 6
        }}
      >
        {
          user ? (
            <>
              {!userId && (
                <UserPersonalDetail
                  user={user}
                  handleClickShareProfile={() => { handleDownloadProfile('share') }}
                  handleDownloadProfile={() => { handleDownloadProfile('download') }}
                />
              )}
              {!userId && (
                <AppointmentDetail
                  user={user}
                />
              )}
              {!userId && (
                <MedicalAnamnesis
                  user={user}
                />
              )}
              <DentalAnamnesis
                user={user}
                handleUpdateDiagnosesStatus={handleUpdateDiagnosesStatus}
                editMode={userId ? true : false}
              />
              <SmileDesign
                user={user}
                handleUpdateSmileDesignStatus={handleUpdateSmileDesignStatus}
                editMode={userId ? true : false}
              />
            </>
          ) : (
            <div>Kullanıcı bulunamadı</div>
          )
        }
      </Paper>
      <UserProfileDialog
        open={shareProfileDialogOpen}
        onClose={() => setShareProfileDialogOpen(false)}
        onSubmit={() => handleSubmitShareProfile()}
        userId={+id!}
      />
      <SelecetClinicDialog
        open={selectClinicDialogOpen}
        onClose={() => setSelectClinicDialogOpen(false)}
        onSubmit={handleSubmitSelectClinic}
        onCopy={() => handleCopyProfileLink()}
      />
    </Box>
  )
}
