import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

export default function AddUserDialog({ open, onClose, onSubmit }: AddUserDialogProps) {

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [birthDate, setBirthDate] = React.useState<Dayjs | null>();

  useEffect(() => {
    if (!open) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setBirthDate(null);
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        Admin Ekle
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({
            firstName,
            lastName,
            email,
            password,
            birthDate:dayjs(birthDate).toISOString()
          });
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <TextField
              fullWidth
              required
              label='Ad'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              label='Soyad'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              type='email'
              label='E-posta'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              required
              label='Şifre'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <DatePicker
              label='Doğum Tarihi'
              value={birthDate}
              onChange={setBirthDate}
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  required: true
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant='outlined'
          >
            İptal
          </Button>
          <Button
            type='submit'
            variant='contained'
          >
            Ekle
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
