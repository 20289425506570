// src/components/MenuItemComponent.tsx

import React, { ReactNode } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { ListItem } from '@mui/material';

interface MenuItemComponentProps {
  icon: ReactNode;
  text: string;
  to: string;
  selected?: boolean;
  color?: string;
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({ icon, text, to, selected, color }) => {

  const theme = useTheme();

  return (
    <Link
      to={to}
      style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
    >
      <ListItemButton
        sx={{
          width: '100%',
          px: 3,
          color: selected ? theme.palette.primary.main : color,
          backgroundColor: 'transparent',
          position: 'relative',
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            '&:after': { // Seçili öğe için sağ tarafta çizgi ekleyin
              content: '""', // İçerik boş bir string olmalı
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: '4px',
              borderRadius: '50px',
              backgroundColor: theme.palette.primary.main, // Temanın birincil rengini kullan
            }
          },
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent'
          },
        }}
        selected={selected}
      >
        <ListItemIcon
          sx={{
            minWidth: '40px',
            color: selected ? theme.palette.primary.main : undefined,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </Link>
  );
};

export default MenuItemComponent;
