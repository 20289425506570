import React from 'react'
import { Box, Breadcrumbs, SxProps, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BreadCrumbItem } from '../utils/types';

type BBlBreadCrumbsProps = {
  items: BreadCrumbItem[];
  sx?: SxProps;
}

export default function CustomBreadCrumbs({ items, sx }: BBlBreadCrumbsProps) {

  const nav = useNavigate();

  return (
    <Box>
      <Breadcrumbs
        separator=">"
        aria-label="breadcrumb"
        sx={{
          ...sx,
        }}
      >
        {items.map((item, index) => (
          <Typography
            key={index}
            onClick={() => item.to && nav(item.to)}
            sx={{
              fontSize: { xs: '10px', sm: '12px' },
              cursor: item.to ? 'pointer' : 'default',
            }}
          >
            {item.label}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  )
}
