import { Box } from '@mui/material';
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import ClinicForm from '../components/ClinicForm';
import { Clinic } from '../utils/types';
import { createClinic, deleteClinic, updateClinic } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthProvider';
import useSWR, { mutate } from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { useAlert } from '../utils/useAlert';
import SimpleSubmitDialog from '../components/SimpleSubmitDialog';

export default function AddEditClinic() {

  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [openDialog, setOpenDialog] = React.useState(false);

  const showSnackbar = useAlert();
  const nav = useNavigate();

  const { data: clinic, isLoading, error } = useSWR<Clinic>(
    () => id ? `${baseUrl}/clinics/${id}` : null,
    (url: string) => fetcher(url, token)
  );

  const handleSubmit = async (values: any) => {
    try {
      if (id) {
        const newClinic = {
          ...values,
          pedodontics: values.expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
          periodontology: values.expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
          surgery: values.expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
          restorative: values.expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
          endodontics: values.expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
          protetics: values.expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
          orthodontics: values.expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
          radiologyGeneral: values.expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
        };
        delete newClinic.expertises;
        const res = await updateClinic(+id, newClinic, token);
        showSnackbar('Klinik başarıyla güncellendi', 'success');
        mutate(`${baseUrl}/clinics/${id}`);
        nav('/clinics');
      } else {
        const newClinic = {
          ...values,
          pedodontics: values.expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
          periodontology: values.expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
          surgery: values.expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
          restorative: values.expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
          endodontics: values.expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
          protetics: values.expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
          orthodontics: values.expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
          radiologyGeneral: values.expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
        };
        delete newClinic.expertises;
        const res = await createClinic(newClinic, token);
        showSnackbar('Klinik başarıyla oluşturuldu', 'success');
        nav('/clinics');
      }
    } catch (error) {
      console.error(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const handleDelete = async () => {
    try {
      const res = await deleteClinic(+id!, token);
      showSnackbar('Klinik başarıyla silindi', 'success');
      mutate(`${baseUrl}/clinics/${id}`);
      nav('/clinics');
    } catch (error) {
      console.error(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  if (id && isLoading) return <div>Loading...</div>
  if (id && error) return <div>Error...</div>

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Klinikler', to: '/clinics' },
          { label: id ? 'Klinik Düzenle' : 'Klinik Ekle' }
        ]}
        title={id ? 'Klinik Düzenle' : 'Klinik Ekle'}
      />

      <ClinicForm
        clinic={clinic}
        handleSubmit={handleSubmit}
        handleDelete={id ? handleOpenDialog : undefined}
      />
      <SimpleSubmitDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Klinik Sil(!)"
        content="Bu kliniği silmek istediğinize emin misiniz? Kliniğe ait tüm randevular ve bilgiler silinecektir. Bu işlem geri alınamaz."
        onSubmit={handleDelete}
      />
    </Box>
  )
}
