import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { TableHeadType } from '../utils/types';
import CustomTableRowItem from './CustomTableRowItem';

type CustomTableProps = {
  heads: TableHeadType[];
  body: React.ReactNode;
}

export default function CustomTable({ heads, body }: CustomTableProps) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '&:last-child th, &:last-child td': {
                  borderBottom: 0,
                },
                backgroundColor: '#E9EDF7',
              }}
            >
              {heads.map((head, index) => (
                <TableCell
                  key={index}
                  sx={{
                    py: 1,
                    ...(index === 0 && {
                      borderRadius: '10px 0 0 10px',
                    }),
                    ...(index === heads.length - 1 && {
                      borderRadius: '0 10px 10px 0',
                    }),
                  }}
                >
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
