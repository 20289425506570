import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { clinicExpertises } from '../utils/defaults';
import { Clinic, Expertise, Role } from '../utils/types';
import { useContext, useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthProvider';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type ClinicFormProps = {
  clinic?: Clinic;
  handleSubmit: (values: any) => void;
  handleDelete?: () => void;
}

export default function ClinicForm({ clinic, handleSubmit, handleDelete }: ClinicFormProps) {

  const [name, setName] = useState(clinic?.name || '');
  const [phone, setPhone] = useState<any>(clinic?.phone || '');
  const [email, setEmail] = useState(clinic?.email || '');
  const [city, setCity] = useState(clinic?.city || '');
  const [district, setDistrict] = useState(clinic?.district || '');
  const [country, setCountry] = useState(clinic?.country || '');
  const [locationUrl, setLocationUrl] = useState(clinic?.locationUrl || '');
  const { role } = useContext(AuthContext);
  const [expertises, setExpertises] = useState(
    clinicExpertises.filter(expertise => {
      return clinic ? clinic[`${expertise.value as Expertise}`] : false;
    }) || []);

  const nav = useNavigate();

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        my: 2
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log(phone)
          handleSubmit({
            name,
            phone,
            email,
            city,
            district,
            country,
            locationUrl,
            expertises
          });
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <TextField
            label="Klinik Adı"
            variant="outlined"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          {/* <TextField
            label="Telefon"
            variant="outlined"
            size="small"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
          /> */}
          <PhoneInput
            international
            defaultCountry="TR"
            value={phone}
            onChange={setPhone}
            numberInputProps={{
              placeholder: 'Telefon Numarası',
              style: {
                border: '1px solid #ccc',
                borderRadius: '5px',
                height: '40px',
                width: '100%',
                background: 'transparent',
              }
            }}
          />
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={clinicExpertises}
            disableCloseOnSelect
            size='small'
            value={expertises}
            onChange={(event, newValue) => {
              setExpertises(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Uzmanlık" placeholder="Uzmanlık" />
            )}
          />
          <TextField
            label="Şehir"
            variant="outlined"
            size="small"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
          />
          <TextField
            label="İlçe"
            variant="outlined"
            size="small"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            fullWidth
          />
          <TextField
            label="Ülke"
            variant="outlined"
            size="small"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            fullWidth
          />
          <TextField
            label="Konum URL"
            variant="outlined"
            size="small"
            value={locationUrl}
            onChange={(e) => setLocationUrl(e.target.value)}
            multiline
            rows={3}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {handleDelete ? role === Role.SUPERADMIN && (
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="error"
            >
              Sil
            </Button>
          ) : <Box></Box>}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                nav('/clinics');
              }}
            >
              İptal
            </Button>
            {role === Role.SUPERADMIN && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Kaydet
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  )
}
