import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

type SimpleConfirmDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  content: string
}

export default function SimpleConfirmDialog({ open, onClose, title, content, onConfirm }: SimpleConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Vazgeç</Button>
        <Button variant='contained' onClick={onConfirm}>Evet</Button>
      </DialogActions>
    </Dialog>
  )
}
