import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from 'react'

type AdminPasswordResetDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}

export default function AdminPasswordResetDialog({ open, onClose, onSubmit }: AdminPasswordResetDialogProps) {

  const [password, setPassword] = React.useState('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        Admin Şifre Sıfırla
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(password);
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <TextField
              label="Yeni Şifre"
              variant="outlined"
              type="password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>İptal</Button>
          <Button type="submit">Kaydet</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
