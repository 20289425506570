import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import CustomButton from './CustomButton'
import { Appointment, Clinic, User } from '../utils/types';
import CustomTable from './CustomTable';
import AppointmentTableRowItem from './AppointmentTableRowItem';
import { addAppointment, updateAppointment } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthProvider';
import { useAlert } from '../utils/useAlert';
import AddEditAppointmentDialog from './AddEditAppointmentDialog';
import { mutate } from 'swr';
import { baseUrl } from '../utils/constants';

type AppointmentDetailProps = {
  user: User;
}

export default function AppointmentDetail({ user }: AppointmentDetailProps) {

  const [open, setOpen] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState<Appointment>();
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();

  const handleClickEdit = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setOpen(true);
  }

  const handleChangeStatus = async (id: number, status: string) => {
    try {
      const res = await updateAppointment(id, { status }, token);
      showSnackbar('Randevu başarıyla güncellendi', 'success');
      mutate(`${baseUrl}/profiles/${user.id}`)
    } catch (error) {
      console.log(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const onClose = () => {
    setOpen(false);
    setSelectedAppointment(undefined);
  }

  const handleAddAppointment = async (date: string, clinic: Clinic, expertises: any, description: string) => {
    try {
      const newAppointment = {
        profileId: user?.id,
        date,
        clinicId: clinic.id,
        description,
        pedodontics: expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
        periodontology: expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
        surgery: expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
        restorative: expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
        endodontics: expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
        protetics: expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
        orthodontics: expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
        radiologyGeneral: expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
      };
      const res = await addAppointment(newAppointment, token);
      setOpen(false);
      showSnackbar('Randevu başarıyla oluşturuldu', 'success');
      mutate(`${baseUrl}/profiles/${user.id}`);
    } catch (error) {
      console.log(error);
      showSnackbar('Randevu oluşturulurken bir hata oluştu', 'error');
    }
  }

  const onSubmit = async (date: string, clinic: Clinic, expertises: any, description: string) => {
    try {
      const newAppointment = {
        profileId: selectedAppointment?.profile.id,
        date,
        clinicId: clinic.id,
        description,
        pedodontics: expertises.find((expertise: any) => expertise.value === 'pedodontics') ? true : false,
        periodontology: expertises.find((expertise: any) => expertise.value === 'periodontology') ? true : false,
        surgery: expertises.find((expertise: any) => expertise.value === 'surgery') ? true : false,
        restorative: expertises.find((expertise: any) => expertise.value === 'restorative') ? true : false,
        endodontics: expertises.find((expertise: any) => expertise.value === 'endodontics') ? true : false,
        protetics: expertises.find((expertise: any) => expertise.value === 'protetics') ? true : false,
        orthodontics: expertises.find((expertise: any) => expertise.value === 'orthodontics') ? true : false,
        radiologyGeneral: expertises.find((expertise: any) => expertise.value === 'radiologyGeneral') ? true : false
      };

      const res = await updateAppointment(selectedAppointment?.id!, newAppointment, token);
      showSnackbar('Randevu başarıyla güncellendi', 'success');
      mutate(`${baseUrl}/profiles/${user.id}`);
      setOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 600,
              color: '#32353E'
            }}
          >
            Randevu Bilgileri
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              color: '#9DA0AF',
            }}
          >
            Burada kullanıcının geçmiş randevularını görüntüleyebilirsiniz
          </Typography>
        </Box>
        <CustomButton
          label="Randevu Ekle"
          variant='contained'
          onClick={() => { setOpen(true)}}
          sx={{
            mt: 2
          }}
        />
      </Box>
      <Box mt={2}>
        <CustomTable
          heads={[
            { label: 'HASTA ADI' },
            { label: 'KLİNİK ADI' },
            { label: 'BÖLÜM' },
            { label: 'TARİH' },
            { label: 'DURUM' },
            { label: 'İŞLEMLER' },
          ]}
          body={
            user.appointment.map((item, index) => (
              <AppointmentTableRowItem
                key={index}
                item={item}
                handleClickEdit={handleClickEdit}
                handleChangeStatus={handleChangeStatus}
              />
            ))
          }
        />
      </Box>
      <AddEditAppointmentDialog
        open={open}
        onClose={onClose}
        appointment={selectedAppointment}
        onSubmit={selectedAppointment ? onSubmit : handleAddAppointment}
      />
    </Box>
  )
}
