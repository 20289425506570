import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import useSWR, { mutate } from 'swr'
import PageHeader from '../components/PageHeader'
import UsersTable from '../components/UsersTable'
import { AuthContext } from '../contexts/AuthProvider'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'
import { ApiList, User } from '../utils/types'
import NotificationDialog from '../components/NotificationDialog'
import { sendNotificationData } from '../services/AppServices'
import { useAlert } from '../utils/useAlert'

export default function MeetRequest() {

  const { token } = useContext(AuthContext);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('interviewDate:desc')
  const showSnackbar = useAlert();
  const [openNotification, setOpenNotification] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [archive, setArchive] = useState(false);

  const { data: users, isLoading, error } = useSWR<ApiList<User>>(
    `${baseUrl}/profiles?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}&orderBy=${orderBy}&interviewStatus=${archive ? 'COMPLETED' : 'WAITING'}`,
    (url: string) => fetcher(url, token));

  useEffect(() => {
    setPage(1);
  }, [recordPerPage, search, archive])

  const handleClickNotification = (id?: number) => {
    setOpenNotification(true);
    setSelectedUser(users?.results.find(user => user.id === id));
  }

  const sendNotification = async (title: string, content: string) => {
    try {
      console.log(title, content);
      const res = await sendNotificationData(title, content, token, selectedUser?.userId);
      showSnackbar('Bildirim başarıyla gönderildi', 'success');
    } catch (error) {
      showSnackbar('Bir hata oluştu', 'error');
      console.log(error);
    }
    setOpenNotification(false);
  }

  const handleMutate = () => {
    mutate(`${baseUrl}/profiles?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}&orderBy=${orderBy}&interviewStatus=${archive ? 'COMPLETED' : 'WAITING'}`);
  }

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Görüşme Talepleri' },
        ]}
        title="Görüşme Talepleri"
      />
      {/* <UsersAppbar /> */}
      <Box
        sx={{
          mt: 2
        }}
      >
        <UsersTable
          users={users?.results || []}
          count={users?.count || 0}
          recordPerPage={recordPerPage}
          setRecordPerPage={setRecordPerPage}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          handleClickNotification={handleClickNotification}
          handleMutate={handleMutate}
          type="interviewStatus"
          archive={archive}
          setArchive={setArchive}
        />
      </Box>
      <NotificationDialog
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        onSend={sendNotification}
        dialogTitle={selectedUser ? `Bildirim Gönder - ${selectedUser?.firstName} ${selectedUser?.lastName}` : 'Herkese Gönder'}
      />
    </Box>
  )
}
