import { Box } from '@mui/material';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import ClinicsTable from '../components/ClinicsTable';
import PageHeader from '../components/PageHeader';
import { AuthContext } from '../contexts/AuthProvider';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { ApiList, Clinic } from '../utils/types';

export default function Clinics() {

  const { token } = useContext(AuthContext);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('')

  const { data: clinics, isLoading, error } = useSWR<ApiList<Clinic>>(
    `${baseUrl}/clinics?skip=${(page - 1) * recordPerPage}&take=${recordPerPage}&search=${search}`,
    (url: string) => fetcher(url, token));

  return (
    <Box>
      <PageHeader
        breadcrumbItems={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Klinikler' },
        ]}
        title="Klinikler"
      />
      <Box
        sx={{
          mt: 2
        }}
      >
        <ClinicsTable
          clinics={clinics?.results || []}
          count={clinics?.count || 0}
          recordPerPage={recordPerPage}
          setRecordPerPage={setRecordPerPage}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
        />
      </Box>
    </Box>
  )
}
