import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import userPic from "../assets/user-pic.png";
import { User } from '../utils/types';
import CustomButton from './CustomButton';

type LastInterviewsStatus = {
  users: User[];
}

export default function LastHealthTourism({ users }: LastInterviewsStatus) {

  const nav = useNavigate();

  return (
    <Box
      sx={{
        mt: 3,
        p: 2,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Sağlık Turizmi
        </Typography>
        <Button
          onClick={() => { nav('/health-tourism') }}
          sx={{
            textTransform: 'none',
          }}
        >
          Tümünü Gör
        </Button>
      </Box>
      <TableContainer
        style={{
          overflowX: 'initial',
        }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead
            sx={{
              '& th': {
                backgroundColor: 'transparent !important'
              }
            }}
          >
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 12 }}>ADI SOYADI</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 12 }}>LOKASYON</Typography>
                </Box>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={{ fontSize: 12 }}>İŞLEMLER</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users?.map((item, index) => (
                <TableRow>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <img style={{ height: 24, width: 24 }} src={item?.image || userPic} alt="user" />
                      <Typography style={{ fontSize: 12 }}>
                        {item.firstName} {item.lastName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {item.city} / {item.country}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                        justifyContent: "flex-end",
                        mx: 1,
                        py: 0.5,
                      }}
                    >
                      <CustomButton
                        label="Detay Göster"
                        variant="outlined"
                        size="small"
                        onClick={() => { nav(`/users/${item.id}`) }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 10,
            width: '100%',
          }}
        >
        </Box>
      </TableContainer>
    </Box>
  )
}
