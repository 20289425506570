import { OpenWithSharp } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useEffect } from 'react'

type NotificationDialogProps = {
  open: boolean;
  onClose: () => void;
  onSend: (title: string, message: string) => void;
  dialogTitle?: string;
}

export default function NotificationDialog({ open, onClose, onSend, dialogTitle }: NotificationDialogProps) {

  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    if (!open) {
      setTitle('');
      setMessage('');
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Başlık"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="message"
          label="Mesaj"
          multiline
          rows={4}
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={() => onSend(title, message)}>Gönder</Button>
      </DialogActions>
    </Dialog>
  )
}
