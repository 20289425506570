import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomTable from './CustomTable'
import DentalAnamnesisTableRowItem from './DentalAnamnesisTableRowItem'
import { User } from '../utils/types'

type DentalAnamnesisProps = {
  user: User;
  editMode?: boolean;
  handleUpdateDiagnosesStatus?: (id: number, status: boolean) => void;
}

export default function DentalAnamnesis({ user, editMode, handleUpdateDiagnosesStatus }: DentalAnamnesisProps) {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: '#32353E'
          }}
        >
          Dental Anamnez
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            color: '#9DA0AF',
          }}
        >
          Burada kullanıcının doldurduğu dental anemnez bilgileri yer almaktadır
        </Typography>
      </Box>
      <Box>
        <CustomTable
          heads={[
            { label: 'TANI', width: '300px' },
            { label: 'ALAN' },
            { label: 'ŞİKAYET' },
            { label: 'TARİH' },
            { label: 'AĞIZ İÇİ FOTOĞRAF' },
            { label: 'AI SONUÇ(HASTA)' },
            { label: 'AI SONUÇ(DOKTOR)' },
          ]}
          body={
            user.diagnosis.map((item, index) => (
              <DentalAnamnesisTableRowItem
                key={index}
                item={item}
                editMode={editMode}
                handleUpdateDiagnosesStatus={handleUpdateDiagnosesStatus}
              />
            ))
          }
        />
      </Box>
    </Box>
  )
}
