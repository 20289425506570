// src/App.tsx

import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/tr';
import { SnackbarProvider } from 'notistack';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Guard from './components/Guard';
import Layout from './components/Layout';
import AuthProvider from './contexts/AuthProvider';
import AddEditClinic from './pages/AddEditClinic';
import Appointments from './pages/Appointments';
import Clinics from './pages/Clinics';
import Home from './pages/Home';
import Login from './pages/Login';
import UserDetail from './pages/UserDetail';
import Users from './pages/Users';
import { lightTheme } from './theme';
import ProfileForClinic from './pages/ProfileForClinic';
import DownloadProfile from './pages/DownloadProfile';
import Dashboard from './pages/Dashboard';
import HealthTourism from './pages/HealthTourism';
import MeetRequest from './pages/MeetRequest';
import AdminUsersContainer from './pages/AdminUsers';
import Campaigns from './pages/Campaigns';

const App = () => {

  return (
    <SnackbarProvider>
      <AuthProvider>
        <ThemeProvider theme={lightTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='tr'>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/profile-page" element={<ProfileForClinic />} />
              </Routes>
              <Guard>
                <Layout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/accounts" element={<AdminUsersContainer />} />
                    <Route path="/health-tourism" element={<HealthTourism />} />
                    <Route path="/meeting-requests" element={<MeetRequest />} />
                    <Route path="/users/:id" element={<UserDetail />} />
                    <Route path="/users/:id/download" element={<DownloadProfile />} />
                    <Route path="/appointments" element={<Appointments />} />
                    <Route path="/clinics" element={<Clinics />} />
                    <Route path="/clinics/new" element={<AddEditClinic />} />
                    <Route path="/clinics/:id/edit" element={<AddEditClinic />} />
                    <Route path="/profile-page" element={<ProfileForClinic />} />
                    <Route path="/campaigns" element={<Campaigns />} />
                  </Routes>
                </Layout>
              </Guard>
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
