import { Box, Button, FormControl, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthProvider';
import { addAdmin, adminPasswordReset, deleteUser, updateAppUser } from '../services/AppServices';
import { Role, User } from '../utils/types';
import { useAlert } from '../utils/useAlert';
import AddUserDialog from './AddUserDialog';
import AdminPasswordResetDialog from './AdminPasswordResetDialog';
import AdminUsersTableRowItem from './AdminUsersTableRowItem';
import OrderByIconButton from './OrderByIconButton';
import SimpleConfirmDialog from './SimpleConfirmDialog';

type AdminUsersTableProps = {
  users: User[];
  count: number;
  recordPerPage: number;
  setRecordPerPage: (value: number) => void;
  page: number;
  setPage: (value: number) => void;
  search: string;
  setSearch: (value: string) => void;
  orderBy: string;
  setOrderBy: (value: string) => void;
  handleMutate: () => void;
}

export default function AdminUsersTable({ handleMutate, orderBy, setOrderBy, users, count, recordPerPage, setRecordPerPage, page, setPage, search, setSearch }: AdminUsersTableProps) {

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [nameOrder, setNameOrder] = useState('asc');
  const [roleOrder, setRoleOrder] = useState('asc');
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();
  const [addAdminDialogOpen, setAddAdminDialogOpen] = useState(false);
  const [adminPasswordResetDialogOpen, setAdminPasswordResetDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleAdminPasswordReset = async (password: string) => {
    try {
      const res = await adminPasswordReset({ userId: selectedUser?.id!, password }, token);
      console.log(res);
      showSnackbar('Admin şifresi başarıyla sıfırlandı', 'success');
      setAdminPasswordResetDialogOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Admin şifresi sıfırlanırken bir hata oluştu', 'error');
    }
  }

  const handleAddAdmin = async (values: any) => {
    try {
      const res = await addAdmin(values, token);
      console.log(res);
      handleMutate();
      showSnackbar('Admin başarıyla eklendi', 'success');
      setAddAdminDialogOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Admin eklenirken bir hata oluştu', 'error');
    }
  }

  const handlePasswordReset = async (id: number) => {
    setSelectedUser(users.find(user => user.id === id) || null);
    setAdminPasswordResetDialogOpen(true);
  }

  const handleChangeRole = async (id: number, role: Role,) => {
    try {
      await updateAppUser(id, { role }, token);
      handleMutate();
    } catch (error) {
      console.log(error);
      showSnackbar('Görüşme durumu güncellenirken bir hata oluştu', 'error');
    }
  }

  const handleOrderBy = (value: string) => {
    if (orderBy === `${value}:asc`) {
      setOrderBy(`${value}:desc`);
      if (value === 'firstName') {
        setNameOrder('desc');
      } else if (value === 'gender') {
        setRoleOrder('desc');
      } 
    } else {
      setOrderBy(`${value}:asc`);
      if (value === 'firstName') {
        setNameOrder('asc');
      } else if (value === 'gender') {
        setRoleOrder('asc');
      } 
    }
  }

  const handleDeleteUserClick = (id: number) => {
    setSelectedUser(users.find(user => user.id === id) || null);
    setDeleteDialogOpen(true);
  }


  const handleDeleteUser = async () => {
    try {
      const res = await deleteUser(selectedUser?.id!, token);
      console.log(res);
      handleMutate();
      showSnackbar('Kullanıcı başarıyla silindi', 'success');
      setDeleteDialogOpen(false);
    } catch (error) {
      console.log(error);
      showSnackbar('Kullanıcı silinirken bir hata oluştu', 'error');
    }
  }

  return (
    <Box>
      <Paper
        sx={{
          py: 3,
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            p: 2
          }}
        >
          <TextField
            label="Ara"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
          <Box sx={{ width: 220 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recordPerPage}
                onChange={(e) => setRecordPerPage(e.target.value as number)}
                size='small'
              >
                <MenuItem value={10}>10 Kayıt Listele</MenuItem>
                <MenuItem value={25}>25 Kayıt Listele</MenuItem>
                <MenuItem value={50}>50 Kayıt Listele</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            variant='contained'
            onClick={() => setAddAdminDialogOpen(true)}
            sx={{
              whiteSpace: 'nowrap',
              textTransform: 'none',
            }}
          >
            Admin Ekle
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%'
          }}
        >
          <TableContainer
            style={{
              overflowX: 'initial',
            }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead
                sx={{
                  '& th': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>ADI SOYADI</Typography>
                      <OrderByIconButton direction={nameOrder} onClick={() => { handleOrderBy('firstName') }} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>EMAİL</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ fontSize: 12 }}>Rol</Typography>
                      <OrderByIconButton direction={roleOrder} onClick={() => { handleOrderBy('role') }} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.map((item, index) => (
                    <AdminUsersTableRowItem
                      key={index}
                      handleChangeRole={handleChangeRole}
                      item={item}
                      handlePasswordReset={handlePasswordReset}
                      handleDeleteUser={handleDeleteUserClick}
                    />
                  ))
                }
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 10,
                width: '100%',
              }}
            >
            </Box>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
            px: 2
          }}
        >
          <Typography>
            {count} kayıt bulundu
          </Typography>
          <Pagination
            count={Math.ceil(count / recordPerPage)}
            page={page}
            onChange={(e, value) => setPage(value)}
            size="small"
          />
        </Box>
      </Paper>
      <AddUserDialog
        open={addAdminDialogOpen}
        onClose={() => setAddAdminDialogOpen(false)}
        onSubmit={handleAddAdmin}
      />
      <AdminPasswordResetDialog
        open={adminPasswordResetDialogOpen}
        onClose={() => setAdminPasswordResetDialogOpen(false)}
        onSubmit={handleAdminPasswordReset}
      />
      <SimpleConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        title='Kullanıcıyı Sil'
        content={`Kullanıcıyı silmek istediğinize emin misiniz?`}
        onConfirm={handleDeleteUser}
      />
    </Box>
  )
}
