import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField } from '@mui/material'
import logo from '../assets/logo.svg';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthProvider';
import { loginUser } from '../services/AuthServices';

export default function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const handleSubmit = async (email: string, password: string) => {
    try {
      setLoading(true);
      const res = await loginUser(email, password);
      localStorage.setItem('token', res.access_token);
      login(res.access_token);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(180deg, #CBE5F6 -23.85%, #F2F8FD 100%)'
      }}
    >
      <Box>
        <div style={{ padding: '10px', textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
          <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <Paper
          sx={{
            minWidth: 300,
            p: 5
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(email, password);
            }}
          >
            <TextField
              label="E-posta Adresi"
              type="email"
              placeholder='E-posta adresinizi girin'
              fullWidth
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Şifre"
              fullWidth
              required
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              placeholder='Şifrenizi girin'
              variant="outlined"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            <LoadingButton
              loading={loading}
              fullWidth
              type='submit'
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                borderRadius: '10px',
                textTransform: 'none'
              }}
            >
              Giriş Yap
            </LoadingButton>
          </form>
        </Paper>
      </Box>
    </Box>
  )
}
