import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getProlife } from '../services/AppServices';
import { User } from '../utils/types';
import { AuthContext } from '../contexts/AuthProvider';
import PrintableProfile from '../components/PrintableProfile';

export default function DownloadProfile() {

  const { id } = useParams();
  const [profile, setProfile] = useState<User>();
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);

  const fetchProfile = async () => {
    try {
      const res = await getProlife(+id!, true, token);
      setProfile(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, [id])

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <PrintableProfile
        user={profile}
      />
    </div>
  )
}
